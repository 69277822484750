const {useState, useRef, useEffect} = require("react");
const {ReactToPrint} = require("react-to-print");
const React = require("react");
/*
 * PrintAsync Component
 *
 * This component handles asynchronous printing using the `react-to-print` library.
 * It allows for delayed rendering of content until it is ready to be printed.
 *
 * THE MAIN REASON FOR THIS COMPONENT IS TO PRINT CONTENT THAT IS NOT VISIBLE ON THE SCREEN
 *
 * The component accepts `content` and `printButton` as props.
 */
export function PrintAsync({content, printButton}) {
    const [isPrinting, setIsPrinting] = useState(false);
    const [isPrintingStarted, setIsPrintingStarted] = useState(false);

    const printRef = useRef(null);

    // We store the resolve Promise being used in `onBeforeGetContent` here
    const promiseResolveRef = useRef(null);

    // We watch for the state to change here, and for the Promise resolve to be available
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
  }, [isPrinting]);

  return (
    <>
      <ReactToPrint
          trigger={() => printButton}
          content={() => printRef.current}
          onBeforeGetContent={() => {
              setIsPrintingStarted(true)
              return new Promise((resolve) => {
                  promiseResolveRef.current = resolve;
                  setIsPrinting(true);
              });
          }}
          onAfterPrint={() => {
              // Reset the Promise resolve so we can print again
              promiseResolveRef.current = null;
              setIsPrinting(false);
              setIsPrintingStarted(false);
          }}
          bodyClass="p-4"
      />
        {/* Hidden content that is rendered when printing starts */}
        <div style={{display: 'none'}}>{isPrintingStarted ? content(printRef) : null}</div>
    </>
  );
}
