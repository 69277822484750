import i18n from "i18next";
import languageDetector from "i18next-browser-languagedetector";

import { initReactI18next } from "react-i18next";


i18n.use(languageDetector).use(initReactI18next).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        height: "Height",
        metric_india: 'Metric (India)',
        metric_israel: 'Metric (Israel)',
        metric_australia: 'Metric (Australia)',
        metric: 'Metric',
        imperial: 'Imperial',
        unit_system: "Unit system",
        recommendations_unit: "Recommendations unit",
        soil_water_deficit: "Soil Water Deficit",
        login_registration_confirmation_message:"Please confirm your registration by clicking the confirmation link you received in your email account.",
        login_registration_after_confirmation_message:"After confirmation, go to",
        login_registration_after_confirmation_message_2:"and login.",
        login_enter_your_email:"Enter your email",
        login_enter_your_password:"Enter your password",
        login_enter_a_valid_email:"Enter a valid email account",
        login_enter_a_valid_confirmation_code:"Enter a valid confirmation code",
        login_forgot_password:"Forgot Password?",
        login_enter_verification_code_message:"Enter the verification code you received in your email account.",
        login_enter_confirmation_code_message:"Enter the confirmation code you received in your email account.",
        login_enter_confirmation_code:"Enter the confirmation code",
        confirmation_code:"Confirmation Code",
        login_new_password:"New Password",
        login_enter_a_valid_password:"Enter a valid password",
        login_dont_have_account_message:"Don't have an account?",
        login_sign_up:"Sign Up",
        login_enter_first_name:"Enter your first name",
        login_enter_last_name:"Enter your last name",
        login_enter_phone_number:"Enter your phone number",
        login_password_limitation_message:"Password must have at least 8 characters with at least one lower and upper case letters.",

        account_first_name_invalid_data_message:"Enter a valid first name",
        account_last_name_invalid_data_message:"Enter a valid last name",
        account_phone_number_invalid_data_message:"Enter a valid phone number",
        account_email_invalid_data_message:"Enter a valid email",
        account_notifications:"Receive Notifications?",
        loading:"Loading...",
        kc_developments:"Development",
        kc_auto_generate:"Auto Generate",

        kc_crop_type_invalid_data_message:"Enter a valid crop type",
        kc_variety:"Variety",
        kc_variety_invalid_data_message:"Enter a valid variety",
        kc_feature:"Feature",
        kc_feature_invalid_data_message:"Enter a valid feature",
        kc_sow_date:"Sow date",
        edit_field_pre_season_irrigation_start_date:"Pre-season irrigation start date",
        kc_sow_date_invalid_data_message:"Enter a valid sow date",
        kc_harvest_date:"Harvest Date",
        kc_harvest_date_invalid_data_message:"Enter a valid harvest date",
        kc_kc_table:"KC Table",
        kc_by_month:"Month",
        kc_by_growth_phase:"Growth phase",
        kc_add_development_phase:"Add a development phase",
        kc_phase_title_placeholder:"Enter phase name...",
        kc_first_phase_starts:"First phase starts on",
        edit_field_choose_area_message:"You must choose an area for your field",
        edit_field_field_name:"Field Name",
        edit_field_last_recommendation:"Last Recommendation",
        edit_field_no_fields:"No fields to display 😟",
        edit_field_field_name_invalid_data_message:"Enter a valid field name",
        edit_field_area_invalid_data_message:"Mark your field area using the draw menu on the left",
        edit:"Edit",
        edit_field_time_zone_invalid_message:"Enter a valid time-zone",
        edit_field_water_meter_sensors:"Flow Meter Sensors",
        edit_field_installation_date:"Installation Date",
        edit_field_installation_date_invalid_message:"Enter a valid installation date",
        edit_field_contract_duration:"Contract End Date",
        edit_field_contract_duration_invalid_message:"Contract duration must be bigger than today",
        edit_field_delete_field_message:"Are you sure you want to delete the field?",
        "cluster":"Cluster",
        "none_default":"None - Default",
        irrigate_command:"Please irrigate",
        get_ready_to_irrigate:"Get ready to irrigate",
        dont_irrigate_command:"No need to irrigate",
        stress_level_very_high:"Stress level is very high",
        stress_level_high:"Stress level is high",
        stress_level_getting_low:"Stress level is getting low",
        stress_level_optimal:"Stress level is optimal",
        stress_level_no_stress:"No Stress",
        no_recommendation_available:"No Recommendation Available",
        no_recommendation:"No Recommendation",
        expiration_date:"Expiration date",
        soil_type:"Soil type",
        crop_type:"Crop type",
        next_actions:"Next Actions",
        take_the_app_to_the_field: "Take N-Drip Connect with you to the field",
        new: '🎉 NEW!',
        history_graph: "History Graph",
        download_the_app: "Download the mobile app",
        coming_soon:"Coming Soon",
        dashboard_irrigation_amount:"Irrigation Amount",
        dashboard_my_fields:"My Fields",
        dashboard_delete_field:"Delete",
        dashboard_copy_field_id:"Copy ID",
        dashboard_edit_field:"Edit",
        dashboard_add_field:"Add Field",
        dashboard_new_field:"New Field",
        dashboard_loading_data_message:"We are gathering data, this should take between 6 to 8 hours",
        no_planner_events: "All clear,\nno future actions at this time",
        management_distribution_units_management:"Distribution Units Management",
        transmitter_admin_page:"Transmitter Test",
        management_admins_only:"admins only",
        management_farming_units_management:"Farming Units Management",
        management_delete_distribution_message:"Are you sure you want to delete the distribution?",
        management_manage_distribution_users_title:"Manage {{distribution}} Users",
        management_enter_a_valid_username:"Enter a valid user name - ex. user@domain.com",
        management_enter_a_valid_distribution_name:"Enter a valid distribution name",
        management_enter_a_valid_farming_unit_name:"Enter a valid farming unit name",
        management_enter_a_valid_region:"Enter a valid region",
        management_farming_unit_name:"Farming unit name",
        management_farming_unit_salesforce_id:"Salesforce ID",
        management_distribution_name:"Distribution Name",
        login_page:"Login Page",
        time_zone:"Time Zone",
        tensiometers:"Tensiometers",
        support_planner:"Support Planner",
        distribution_unit:"Distribution Unit",
        farming_unit:"Farming Unit",
        field_amount:"Fields Amount",
        region:"Region",
        actions:"Actions",
        close:"Close",
        user:"User",
        role:"Role",
        admin:"Admin",
        viewer:"Viewer",
        moderator:"Moderator",
        account:"Account",
        logout:"Log out",
        first_name:"First Name",
        last_name:"Last Name",
        phone_number:"Phone Number",
        email:"Email",
        units:"Units",
        area:"Area",
        gdd:"GDD",
        leaf_length:"Leaf Length",
        'et0':"ETo",
        leached:"Leached",
        plant:"Plant",
        soil:"Soil",
        temperature:"Temperature",
        weight:"Weight",
        pressure:"Pressure",
        length:"Length",
        volume:"Volume",
        estimated_nitrogen_values:"Estimated nitrogen values",

        temperature_celsius:"Celsius",
        temperature_fahrenheit:"Fahrenheit",
        weight_kg:"Kg",
        weight_lb:"lb",
        weight_tonne:"Tonne",
        weight_short_ton:"Short Ton",
        pressure_kpa:"kPa",
        pressure_psi:"PSI",
        pressure_cbar:"CBar",
        pressure_mm:"mm",
        pressure_pa:"Pa",
        length_mm:"mm",
        length_inch:"Inch",
        volume_cubic_metre:"Cubic Meter",
        volume_gallon:"Gallon",
        volume_bushel:"Bushel",
        volume_acre_foot:"Acre Foot",
        volume_acre_inch:"Acre Inch",
        volume_megaliter:"Megaliter",

        phase:"Phase",
        kc:"Kc",
        duration:"Duration",
        days:"Days",

        months_january:'January',
        months_february:'February',
        months_march:'March',
        months_april:'April',
        months_may:'May',
        months_june:'June',
        months_july:'July',
        months_august:'August',
        months_september:'September',
        months_october:'October',
        months_november:'November',
        months_december:'December',
        show_archived:"Show archived",
        choose_date:"Choose Date",
        last_day:"Last Day",
        last_week:"Last Week",
        last_two_weeks:"Last 2 Weeks",
        last_month:"Last Month",
        last_six_months:"Last 6 Months",
        last_year:"Last Year",
        activity_dates:"Activity dates",

        supporting_data:"Supporting Data",
        water_stress_level:"Water Stress Level",
        fertilization_gauge_title:"Nitrogen Levels",
        map_view:"Map View",

        serial_no:"Serial no.",
        sensor_serial_no:"Sensor's Serial no.",
        sensor_latitude:"Sensor's Latitude",
        sensor_longitude:"Sensor's Longitude",
        water_meter_units:"M³ per pulse",
        name:"Name",
        latitude:"Latitude",
        longitude:"Longitude",

        create:"Create",
        update:"Update",
        field:"Field",
        cancel:"Cancel",
        delete:"Delete",
        ok:"OK",
        confirmation:"Confirmation",
        confirm:"Confirm",
        yes:"Yes",
        no:"No",
        home:"Home",
        change:"Change",
        forgot:"Forgot",
        password:"Password",
        login:"Login",
        prefix:"Prefix",
        register:"Register",
        settings:"Settings",
        based_on:"Based on",
        amount:"Amount",
        date_and_time:"Date & Time",
        report:"Report",
        hours:"Hours",
        report_irrigation:"Report Irrigation",
        report_nitrogen:"Report Nitrogen",
        irrigation_reported_successfully:"Irrigation Reported Successfully",
        fertilization_reported_successfully:"Fertilization Reported Successfully",
        rain_reported_successfully:"Rain Reported Successfully",
        wtr_reported_successfully:"Water Treatment Reported Successfully",
        is_manure:"Is Manure?",
        irrigation:"Irrigation",
        select_sensors:"Select Sensors",
        select_all:"Select All",
        manure:"Manure",
        nitrogen:"Nitrogen",
        fertilization_history:"Fertilization History",
        water_pressure_history:"Water Pressure History",
        designed_pressure:"Designed pressure",
        support_ndvi:"Support NDVI?",
        support_coup_model:"Support Coup Model?",
        n_in_organic_matter:"N in Organic Matter",
        initial_concentrations:"Initial Concentrations",
        should_automate_irrigation: "Automate Irrigation",
        location:"Location",
        i_agree_to:"I agree to ",
        terms_and_conditions:"terms and conditions",
        language:"Language",
        last_update: "Last update: ",
        hours_ago:"{0}h ago",
        hour_ago:"{0}h ago",
        day_ago:"{0}d ago",
        days_ago:"{0}d ago",
        minute_ago:"{0}min ago",
        minutes_ago:"{0}min ago",
        today:"today",
        yesterday:"yesterday",
        enter_transmitter_id:"Enter the transmitter ID",
        transmitter_id:"Transmitter ID",
        sim_activation:"Sim is being activated, please wait 10 minutes before testing again",
        last_seen:"Last Seen",
        recent_analog_values:"Recent Analog Values",
        recent_connectivity_values:"Recent Connectivity Values",
        recent_battery_values:"Recent Battery Values",
        recent_c1_values:"Recent C1 Values",
        recent_errors_values:"Recent Error Values",
        installation_date: "Installation Date",
        modem_version:"Modem Version",
        modem_hardware:"Modem Hardware",
        iccid:"ICCID",
        sensor_batch: "Sensor Batch",
        transmission_interval: "Transmission Interval",
        sample_interval: "Sample Interval",
        micro_version: "Micro Version",
        sim_last_event: "SIM last event",
        date:"Date",
        value:"Value",
        test:"Test",
        Sand:"Sand",
        "Loamy Sand":"Loamy Sand",
        "Sandy Loam":"Sandy Loam",
        Loam:"Loam",
        Silt:"Silt",
        "Silty Loam":"Silty Loam",
        "Sandy Clay Loam":"Sandy Clay Loam",
        "Clay Loam":"Clay Loam",
        "Silty Clay Loam":"Silty Clay Loam",
        "Sandy Clay":"Sandy Clay",
        "Silty Clay":"Silty Clay",
        Clay:"Clay",
        "Mix of Peat types":"Mix of Peat types",
        "Coconut fiber":"Coconut fiber",
        "Mixed Tuff":"Mixed Tuff",
        water_potential_graph:"Water potential graph",
        "Cabbage":"Cabbage",
        "Carrot":"Carrot",
        "Cauliflower":"Cauliflower",
        "Celery":"Celery",
        "Lettuce":"Lettuce",
        "Onion":"Onion",
        "Spinach":"Spinach",
        "Radish":"Radish",
        "Eggplant":"Eggplant",
        "Sweet pepper":"Sweet pepper",
        "Tomato":"Tomato",
        "Cantaloupe":"Cantaloupe",
        "Cucumber":"Cucumber",
        "Pumpkin (Winter Squash)":"Pumpkin (Winter Squash)",
        "Squash":"Squash",
        "Zucchini":"Zucchini",
        "Sweet Melon":"Sweet Melon",
        "Beet":"Beet",
        "Cassava":"Cassava",
        "Potato":"Potato",
        "Sweet potato":"Sweet potato",
        "Sugar Beet":"Sugar Beet",
        "Bean":"Bean",
        "Faba bean, broad bean":"Faba bean, broad bean",
        "Green Gram":"Green Gram",
        "Cowpea":"Cowpea",
        "Peanut (Groundnut)":"Peanut (Groundnut)",
        "Lentil":"Lentil",
        "Pea":"Pea",
        "Soybean":"Soybean",
        "Artichoke":"Artichoke",
        "Asparagus":"Asparagus",
        "Cotton":"Cotton",
        "Flax":"Flax",
        "Castorbean (Ricinus)":"Castorbean (Ricinus)",
        "Safflower":"Safflower",
        "Sesame":"Sesame",
        "Sunflower":"Sunflower",
        "Barley":"Barley",
        "Oat":"Oat",
        "Wheat":"Wheat",
        "Maize":"Maize",
        "Millet":"Millet",
        "Sorghum":"Sorghum",
        "Rice":"Rice",
        "Alfalfa":"Alfalfa",
        "Bermuda":"Bermuda",
        "Sudan":"Sudan",
        "Sugarcane":"Sugarcane",
        "Banana":"Banana",
        "Pineapple":"Pineapple",
        "Grape":"Grape",
        "Hop":"Hop",
        "Citrus":"Citrus",
        "Olive":"Olive",
        "Pistachio":"Pistachio",
        "Walnut":"Walnut",
        "Cattails, Bulrush":"Cattails, Bulrush",
        "Mango":"Mango",
        "Grapefruit":"Grapefruit",
        "Garden":"Garden",
        "Grass":"Grass",
        "Genus Citrus":"Genus Citrus",
        "Avocado":"Avocado",
        "Coffee":"Coffee",
        "Corn":"Corn",
        "Lychee":"Lychee",
        "Peach, Nectarine":"Peach, Nectarine",
        "Watermelon":"Watermelon",
        "Date Palm":"Date Palm",
        "Pomegranate":"Pomegranate",
        "Cannabis":"Cannabis",
        "Pepper":"Pepper",
        "Blackberry":"Blackberry",
        "Strawberry":"Strawberry",
        "Pecan": "Pecan",
        "start": "Start",
        "end": "End",
        "discharge": "Discharge",
        "recent wfr samples": "Recent Flow Meter Values",
        "day_sunday": "Sunday",
        "day_monday": "Monday",
        "day_tuesday": "Tuesday",
        "day_wednesday": "Wednesday",
        "day_thursday": "Thursday",
        "day_friday": "Friday",
        "day_saturday": "Saturday",
        "planning":"Planning",
        "monitoring": "Monitoring",
        "planning_days": "Days",
        "planning_weeks": "Weeks",
        "planning_publish": "Publish",
        "planning_planned": "planned",
        "planning_used": "used",
        "tomorrow":"tomorrow",
        "later":"later",
        "event": "Event",
        "events": "events",
        "fertilization": "Fertilization",
        "hcl": "HCl",
        "hpo": "HPO",
        "note": "Footnote",
        "planning_note_placeholder": "Write your massage here",
        'out of': 'out of',
        'accumulated this week': 'accumulated this week',
        'accumulated this season': 'accumulated this season',
        sensors:"Sensors",
        valves:"Valves",
        type:"Type",
        "coming up":"coming up",
        "notify": "notify",
        "irrigation_system":"Irrigation System",
        "irrigation_type":"Irrigation Type",
        "irrigation_rate":"Irrigation Rate",
        "irrigation_time_frames":"Irrigation Time Frames",
        "define_time_frames":"Define available time frames for irrigation",
        "start_day":"Start day",
        "start_hour":"Start hour",
        "end_hour":"End hour",
        "time_frame_conflict":"Selected Time Frame conflicts with existing Time Frame",
        "select_different_hour":"Please select Different Hour",
        "select_different_day":"Please select Different Day",
        "no_time_frames":"There are no irrigation time frames constraints",
        "for_this_field":"for this field at the moment",
        locate_coordinates:"Locate coordinates",
        ignore_sensor_during_calculations:"Ignore during calculations",
        ignore_sensor_during_calculations_and_hide:"Ignore during calculations and hide",
        consider_as_flow_meter:"Consider as flow meter",
        alerts:"alerts",
        stop_data_collection:"Stop data collection",
        accumulated_water_input:"Accumulated water input",
        precipitation:"Precipitation",
        "water_potential":"Water potential",
        petiole:"Petiole",
        "minimum":"Min",
        "maximum":"Max",
        "max_bigger_than_min":"Max value need to be bigger than the Min value",
        "min_smaller_than_max":"Min value need to be smaller than the Max value",
        updated:"Updated",
        "not_yet_set":"Not yet set",
        "missing_values":"Values are missing",
        "start_date":"Start date",
        "end_date":"End date",
        "fertigation_concentrations":"Fertigation concentrations",
        "no_fertigation_concentrations_message":"There are no fertigation concntrations",
        "general_coup_settings":"General Coup Model settings",
        "days_from_sowing":"Days from Sowing",
        "field_size":"Field size",
        "sensor_type":"Sensor type",
        "sensor_sub_type":"Sensor Sub type",
        "duplicate_field": "Duplicate Field",
        "duplicate": "Duplicate",
        dashboard_move_to_archive:"Archive",
        dashboard_unarchived:'Unarchived',
        alphabetic:"Alphabetic",
        "sort_by": "Sort by",
        "Almond": "Almond",
        "Apple": "Apple",
        "Arugula": "Arugula",
        "Blueberry": "Blueberry",
        "Broccoli": "Broccoli",
        "Faba Bean, Broad Bean": "Faba Bean, Broad Bean",
        "Fig": "Fig",
        "Garlic": "Garlic",
        "Guayule": "Guayule",
        "Jojoba": "Jojoba",
        "Kiwi": "Kiwi",
        "Loquat": "Loquat",
        "Mandarin": "Mandarin",
        "Moringa": "Moringa",
        "Nectarine": "Nectarine",
        "Orange": "Orange",
        "Peach": "Peach",
        "Peony": "Peony",
        "Raspberry": "Raspberry",
        "Sweet Cherry": "Sweet Cherry",
        "Sweet Pepper": "Sweet Pepper",
        "Sweet Potato": "Sweet Potato",
        "1st Cutting Cycle": "1st Cutting Cycle",
        "Other Cutting Cycles": "Other Cutting Cycles",
        "Clay (Red Oxisol) (Pivot Irrigation)": "Clay (Red Oxisol) (Pivot Irrigation)",
        "Clay (Red Oxisol)": "Clay (Red Oxisol)",
        "Coconut Fiber": "Coconut Fiber",
        "Silt Loam": "Silt Loam",
        "Clay (Ramot Gonen Pardes)": "Clay (Ramot Gonen Pardes)",
        "Clay Loam (6mm)": "Clay Loam (6mm)",
        "Sandy Clay (Shefayim)": "Sandy Clay (Shefayim)",
        "Clay 1000mm": "Clay 1000mm",
        "Clay Loam Et": "Clay Loam Et",
        "Loam (Agroterra)": "Loam (Agroterra)",
        "Clay (Ramot Gonen)": "Clay (Ramot Gonen)",
        "Clay (Red Oxisol) Au": "Clay (Red Oxisol) Au",
        "Clay Loam (Peanuts)": "Clay Loam (Peanuts)",
        "Clay (Adashim)": "Clay (Adashim)",
        "Clay Loam Au": "Clay Loam Au",
        "Clay (Pivot Irrigation)": "Clay (Pivot Irrigation)",
        "Clay Au": "Clay Au",
        "Clay (Cotton)": "Clay (Cotton)",
        "Loam (6mm)": "Loam (6mm)",
        "Clay Loam (Daliot)": "Clay Loam (Daliot)",
        "Sand Ornamental": "Sand Ornamental",
        "Clay Loam (Pivot Irrigation)": "Clay Loam (Pivot Irrigation)",
        "Mix Of Peat Types": "Mix Of Peat Types",
        "Clay Loam (Ak-Chin)": "Clay Loam (Ak-Chin)",
        "Planner":"Planner",
        "Planned irrigation": "Planned irrigation",
        "Irrigated": "Irrigated",
        "Nitrogen graph": "Nitrogen graph",
        "search_by_field":"Search By Field",
        "free_search":"Free Search",
        "transmitters":"Transmitters",
        "distribution":"Distribution",
        "week":"Week",
        "season":"Season",
        "reported": "Reported",
        "events_list":"Events List",
        "no_reported":"No Reported Events",
        "transmitter_name":"Transmitter Name",
        "details":"details",
        "discharge over time":"Discharge over time",
        "select_water_input": "Select Water Input",
        "etc":"ETc",
        next_day: "Next day",
        crop_management: "Crop Management",
        "insights":"Insights",
        "number_of_fields": "Number of fields",
        "total_area": "Total area",
        "total_water_usage": "Total water usage",
        "total_fertilizers_usage": "Total fertilizers usage",
        "total_nitrogen_leached": "Total nitrogen leached",
        "crop_types": "Crop types",
        "report_event": "Report event",
        "save": "Save",
        "measurement": "measurement",
        'rain': 'rain',
        'starting_time': 'starting time',
        "please select": "Please Select",
        "create_planner_item": "add new planner item",
        "update_planner_item": "update planner item",
        "containing": "containing",
        "no_cluster": "No Cluster",
        "offset": "Offset",
        "notes": "Notes",
        "week_total": "Week total",
        "season_total": "Season total",
        "selected_field_only": "Selected field only",
        "farming_type": "Farming type",
        "plant_height": "Plant Height",
        "fert_tank_height": "Fert tank level",
        "Fert_tank_volume": "Fert tank volume",
        "recommendations_tooltip": "Recommendations are insights that help optimize yields and improve decision-making",
        "allow_notifications": "Allow Notifications",
        "notifications_channels": "In which channels you will be notified",
        "event_type": "Event Type",
        "login_enter_your_email_forgot_password": "Enter your email and we will send you a code for resetting your password:",
        "push": "Push",
        "recommendations": "Recommendations",
        "field_notifications": "Which notifications you will receive for each field",
        "none": "None",
        "notifications": "Notifications",
        "notifications_phase": "Control notification types and channels by which you will be notified, per each field",
        "alerts_tooltip": "Alerts flag adverse events that require immediate action, to protect your crops",
        "all_fields": "All fields",
        "current_field": "Current field",
        "alerts_message_continuous_irrigation_notification_center_title": "Continuous irrigation alert - In fieldX",
        "alerts_message_continuous_irrigation_notification_center_body": "We've detected continuous irrigation for over 2 days in the field. To protect your crops and resources, we strongly advise inspecting the field for over-irrigation.",
        "alerts_message_sensor_extreme_dry_conditions_notification_center_title": "Extreme dry conditions detected in the field - Data received from sensorX",
        "alerts_message_sensor_extreme_dry_conditions_notification_center_body": "Data from sensorX indicates that conditions in the field are extremely dry. To ensure accurate data and protect your crops, we recommend inspecting the irrigation plan, and the irrigation system around the sensor",
        "alerts_message_long_dry_period_notification_center_title": "Long period without irrigation - Irrigation of fieldX advised",
        "alerts_message_long_dry_period_notification_center_body": "fieldX has not received water for at least 10 days, contributing to the water stress level We advise irrigating the field to safeguard your crops, according to your N-Drip recommendations.",
        "alerts_message_drying_trend_notification_center_title": "Drying trend detected in your field",
        "alerts_message_wetting_trend_notification_center_title": "Wetting trend detected in your field",
        "alerts_message_drying_trend_notification_center_body": "We’ve noticed a decreasing trend of the soil water potential in fieldX, below the recommended level. To optimize crop health, please review your current irrigation practice and adjust according to N-Drip recommendations.",
        "alerts_message_wetting_trend_notification_center_body": "We’ve noticed an increasing trend of the soil water potential in fieldX, above the recommended level. To optimize crop health, please review your current irrigation practice and adjust according to N-Drip recommendations.",
        "alerts_message_excessive_heat_notification_center_title": "Heat wave alert",
        "alerts_message_excessive_heat_notification_center_body": "A significant heat wave is forecast for the fieldX area in the coming days. To protect your crops from these extreme weather conditions and ensure adequate watering, please review and implement our updated irrigation recommendations",
        "alerts_message_extreme_cold_notification_center_title": "Cold wave alert",
        "alerts_message_extreme_cold_notification_center_body": "A significant cold wave is forecast for the fieldX area in the coming days. To counteract the freezing weather conditions and protect your crops from frost damage, please review and implement our updated irrigation recommendations.",
        "alerts_message_dryland_long_dry_period_notification_center_title": "Long dry period detected",
        "alerts_message_dryland_long_dry_period_notification_center_body": "fieldX has not received water for at least 10 days, contributing to the water stress level.",
        "alerts_message_dryland_sensor_exterme_dry_notification_center_title": "Extreme dry conditions detected in the field - Data received from sensorX",
        "alerts_message_dryland_sensor_exterme_dry_notification_center_body": "Data from sensorX indicates that conditions in the field are extremely dry",
        "alerts_message_dryland_drying_trend_notification_center_title": "Drying trend detected in your field",
        "alerts_message_dryland_drying_trend_notification_center_body": "We’ve noticed a decreasing trend of the soil water potential in fieldX, contributing to the water stress level",
        "alerts_message_low_tank_fert_notification_center_title": "Low fertilizer level detected in fieldX",
        "alerts_message_low_tank_fert_notification_center_body": "We have detected that the fertilizer level in the tank at fieldX has reached the minimum threshold of 20 cm. Please arrange to refill the tank to ensure continued fertilization of the crops.",
        "alerts_message_dryland_wetting_trend_notification_center_title": "Wetting trend detected in your field",
        "alerts_message_dryland_wetting_trend_notification_center_body": "We’ve noticed an increasing trend of the soil water potential in fieldX",
        "alerts_message_dryland_excessive_heat_notification_center_title": "Heat wave alert",
        "alerts_message_dryland_excessive_heat_notification_center_body": "A significant heat wave is forecast for the fieldX area in the coming days",
        "alerts_message_dryland_extreme_cold_notification_center_title": "Cold wave alert",
        "alerts_message_dryland_extreme_cold_notification_center_body": "A significant cold wave is forecast for the fieldX area in the coming days.",
        "alerts_message_dryland_ndvi_notification_center_title": "Decreasing vegetative activity",
        "alerts_message_dryland_ndvi_notification_center_body": "Our recent analysis of NDVI image history has revealed a notable decrease in vegetation growth within fieldX. To optimize crop health, please review your current irrigation practice.",
        "no_notifications": "There are no notifications at the moment",
        "notifications_welcome_message_title": "Welcome to the new notifications panel",
        "notifications_welcome_message_body": "Stay on top of current and expected important events in your fields trough desktop and mobile",
        "planner_days_shorts_sun": "Sun",
        "planner_days_shorts_mon": "Mon ",
        "planner_days_shorts_tue": "Tue",
        "planner_days_shorts_wed": "Wed",
        "planner_days_shorts_thu": "Thu",
        "planner_days_shorts_fri": "Fri",
        "planner_days_shorts_sat": "Sat",
        "shorts_months_january": "Jan",
        "shorts_months_february": "Feb",
        "shorts_months_march": "Mar",
        "shorts_months_april": "Apr",
        "shorts_months_may": "May",
        "shorts_months_june": "Jun",
        "shorts_months_july": "Jul",
        "shorts_months_august": "Aug",
        "shorts_months_september": "Sep",
        "shorts_months_october": "Oct",
        "shorts_months_november": "Nov",
        "shorts_months_december": "Dec",
        "dont_show_again": "Don’t show again",
        "notifications_settings": "Notifications settings",
        "read_more": "Read more",
        "show_less": "Show less",
        "distribution_menu_select": "Select Distribution",
        "farming_unit_menu_select": "Select Farming Unit",
        "field_menu_select": "Select Field",
        "recent_field": "Recent field",
        "discharge_test": "Discharge Test",
        "planner_season_start": "Season start",
        "planner_season_end": "Season end",
        "planner_growth_phase_initial": "Initial",
        "planner_growth_phase_development": "Development",
        "planner_growth_phase_middle": "Middle",
        "planner_growth_phase_late": "Late",
        "planner_export_button": "Export",
        "planner_add_event_button": "Add event",
        "planner_water_treatment": "Water treatment",
        "planner_add_button": "Add",
        "planner_start_time": "Start time",
        "country": "Country",
        "monday_id": "Monday ID",
        "delete_item_title": "The item will be deleted",
        "delete_item_text": "This action can not be reverted and the data will be lost",
        "average": "Average",
        "seconds": "sec",
        "event_delete_successfully": "The event was deleted successfully",
        "reported_successfully": "The test has been reported successfully",
        "report_unsuccessfully": "The event cannot be reported at this time. Please try again later",
        "event_delete_unsuccessfully": "The event cannot be deleted at this moment, please try again later",
        "Insights_title_county": "County",
        "Insights_group_by": "Group by",
        "edit_field_measured_irrigation_rate": "Measured irrigation rate",
        "edit_field_custom_irrigation_rate": "Custom irrigation rate",
        "edit_field_representative_input_checkbox": "Use measured irrigation rate once available. This will impact the Planner",
        "edit_field_tank_type": "Tank type",
        "wtr_checkbox":"Include water treatment",
        "kc_phases_stress_checkbox": "Apply stress",
        "kc_phases_stress_tooltip": "Start a planned period of crop water stress according to the following percentage reduction in ETc",
        "edit_field_irrigation_timeframe_source_soil": "Soil type limitation",
        "edit_field_irrigation_timeframe_tooltip": "Original limitation:",
        "edit_field_irrigation_timeframe_source_irrigation_rate": "Irrigation rate",
        "edit_field_irrigation_timeframe_source_user": "Defined by user",
        "source": "Source",
        "discharge_result_exceeding_hint": "Test result deviates from the discharge trend",
        "Timeframe_end_of_season": "End of season",
        "Farming_unit_entity_basic_info": "Basic info",
        "Farming_unit_entity_add_new_button": "Add new",
      }
    },
    pt: {
      translations:
      {
        edit_field_pre_season_irrigation_start_date:"Data de início da irrigação pré-temporada",
        login_registration_confirmation_message:"Por favor, confirme sua inscrição clicando no link de confirmação enviado para o seu email.",
        login_registration_after_confirmation_message:"Após a confirmação, vá até",
        login_registration_after_confirmation_message_2:"e Entrar.",
        login_enter_your_email:"Insira seu endereço de email",
        login_enter_your_password:"Insira sua senha",
        login_enter_a_valid_email:"Insira uma conta de email válida",
        login_enter_a_valid_confirmation_code:"Insira um código de confirmação válido",
        login_forgot_password:"Esqueceu sua senha?",
        login_enter_verification_code_message:"Insira o código de verificação recebido no seu email.",
        login_enter_confirmation_code_message:"Insira o código de confirmação recebido no seu email.",
        login_enter_confirmation_code:"Insira o código de confirmação",
        confirmation_code:"Código de confirmação",
        login_new_password:"Nova senha",
        login_enter_a_valid_password:"Insira uma senha válida",
        login_dont_have_account_message:"Não possui uma conta?",
        login_sign_up:"Increver-se",
        login_enter_first_name:"Insira seu primeiro nome",
        login_enter_last_name:"Insira seu sobrenome",
        login_enter_phone_number:"Insira seu número de telefone",
        login_password_limitation_message:"Sua senha deverá possuir no mínimo 8 caracteres, sendo pelo menos uma letra maiúscula e uma letra minúscula.",
        account_first_name_invalid_data_message:"Insira um primeiro nome válido",
        account_last_name_invalid_data_message:"Insira um sobrenome válido",
        account_phone_number_invalid_data_message:"Insira um número de telefone válido",
        account_email_invalid_data_message:"Insira um endereço de email válido",
        account_notifications:"Deseja receber notificações de irrigação?",
        loading:"Carregando...",
        kc_developments:"Desenvolvimento",
        kc_auto_generate:"Gerar automaticamente",
        kc_crop_type_invalid_data_message:"Insira uma cultura válida",
        kc_variety:"Variedade",
        kc_variety_invalid_data_message:"Insira uma variedade válida",
        kc_feature:"Característica ",
        kc_feature_invalid_data_message:"Insira uma característica válida",
        kc_sow_date:"Data de plantio",
        kc_sow_date_invalid_data_message:"Insira uma data de plantio válida",
        kc_kc_table:"Tabela de Kc",
        kc_by_month:"Mensal",
        kc_by_growth_phase:"Fases de crescimento",
        kc_add_development_phase:"Adicionar uma fase de desenvolvimento",
        kc_phase_title_placeholder:"Inserir título da fase de crescimento",
        kc_first_phase_starts:"Primeira fase inicia-se em",
        edit_field_choose_area_message:"Demarque a área da lavoura",
        edit_field_field_name:"Nome da plantação",
        edit_field_last_recommendation:"Última recomendação",
        edit_field_no_fields:"Nenhuma plantação a ser exibida",
        edit_field_field_name_invalid_data_message:"Insira um nome de plantação válido",
        edit_field_area_invalid_data_message:"Demarque a área de sua lavoura utilizando o menu à esquerda",
        edit_field_time_zone_invalid_message:"Insira um fuso horário válido",
        edit_field_water_meter_sensors:"Sensores de Medição de Água de Irrigação",
        edit_field_installation_date:"Data de instalação",
        edit_field_installation_date_invalid_message:"Insira uma data de instalação válida",
        edit_field_contract_duration:"Dia de término do contrato",
        edit_field_contract_duration_invalid_message:"Duração do contrato deve ser maor que hoje",
        edit_field_delete_field_message:"Tem certeza que deseja excluir a plantação?",
        irrigate_command:"Por favor, irrigue",
        get_ready_to_irrigate:"Prepare-se para a irrigação",
        dont_irrigate_command:"Não há necessidade de irrigação",
        stress_level_very_high:"Nível de estresse é muito alto",
        stress_level_high:"Nível de estresse é alto",
        stress_level_getting_low:"Nível de estresse está reduzindo",
        stress_level_optimal:"Nível de estresse é ótimo",
        no_recommendation_available:"Recomendação não disponível",
        expiration_date:"Data de vencimento do contrato",
        soil_type:"Solo",
        crop_type:"Cultura",
        based_on:"baseado em",
        test:"Teste",
        dashboard_irrigation_amount:"Lâmina de irrigação",
        dashboard_my_fields:"Minhas plantações",
        dashboard_delete_field:"Excluir",
        dashboard_copy_field_id:"Copiar ID",
        dashboard_edit_field:"Editar",
        dashboard_add_field:"Adicionar plantação",
        dashboard_new_field:"Nova plantação",
        dashboard_loading_data_message:"Estamos coletando dados pelas próximas 6-8 horas",
        management_distribution_units_management:"Gestão das Unidades de Distribuição",
        transmitter_admin_page:"Avaliação dos transmissores",
        management_admins_only:"Apenas administradores",
        management_farming_units_management:"Gestão das Unidades Agrícolas",
        management_delete_distribution_message:"Tem certeza que deseja excluir a unidade de distribuição?",
        management_manage_distribution_users_title:"Gestão {{Distribuição}} Usuários",
        management_enter_a_valid_username:"Insira um nome de usuário válido - ex. user@domain.com",
        management_enter_a_valid_distribution_name:"Insira um nome de distribuição válido",
        management_enter_a_valid_farming_unit_name:"Insira um nome de unidade agrícola válido",
        management_enter_a_valid_region:"Insira uma região válida",
        management_farming_unit_name:"Nome da Unidade Agrícola",
        management_farming_unit_salesforce_id:"Salesforce ID",
        management_distribution_name:"Nome da Unidade de Distribuição",
        time_zone:"Fuso horário",
        tensiometers:"Tensiômetros",
        distribution_unit:"Unidade de Distribuição",
        farming_unit:"Unidade Agrícola",
        field_amount:"Número de plantações",
        region:"Região",
        actions:"Ações",
        close:"Fechar",
        user:"Usuário",
        role:"Função",
        admin:"Administrador",
        viewer:"Operador",
        account:"Conta",
        logout:"Sair",
        first_name:"Primeiro nome",
        last_name:"Sobrenome",
        phone_number:"Número de telefone",
        email:"Email",
        units:"Unidades",
        area:"Área",
        gdd:"GDD",
        kc:"Kc",
        et0:"ETo",
        leached:"Lixiviado",
        plant:"Planta",
        soil:"Solo",
        temperature:"Temperatura",
        weight:"Massa",
        pressure:"Pressão",
        length:"Comprimento",
        volume:"Volume",
        estimated_nitrogen_values:"Valores estimados de nitrogênio",
        temperature_celsius:"Celsius",
        temperature_fahrenheit:"Fahrenheit",
        weight_kg:"Kg",
        weight_lb:"lb",
        weight_tonne:"Tonelada",
        weight_short_ton:"Tonelada curta",
        pressure_kpa:"kPa",
        pressure_psi:"PSI",
        pressure_cbar:"CBar",
        pressure_mm:"mm",
        pressure_pa:"Pa",
        length_mm:"mm",
        length_inch:"Polegada",
        volume_cubic_metre:"Metro cúbico",
        volume_gallon:"Galão",
        volume_bushel:"Bushel",
        volume_acre_foot:"Acre-pé",
        volume_acre_inch:"Acre-polegada",
        volume_megaliter:"Megalitro",
        phase:"Fase",
        duration:"Duração",
        days:"Dias",
        months_january:"Janeiro",
        months_february:"Fevereiro",
        months_march:"Março",
        months_april:"Abril",
        months_may:"Maio",
        months_june:"Junho",
        months_july:"Julho",
        months_august:"Agosto",
        months_september:"Setembro",
        months_october:"Outubro",
        months_november:"Novembro",
        months_december:"Dezembro",
        choose_date:"Selecione a data",
        last_day:"Último dia",
        last_week:"Última semana",
        last_two_weeks:"Últimas 2 semanas",
        last_month:"Último mês",
        last_six_months:"Últimos 6 meses",
        supporting_data:"Dados de apoio",
        water_stress_level:"Nível de estresse hídrico",
        fertilization_gauge_title:"Nível de nitrogênio",
        map_view:"Visualização do mapa",
        serial_no:"Número de série",
        sensor_serial_no:"Número de série do sensor",
        sensor_latitude:"Latitude do sensor",
        sensor_longitude:"Longitude do sensor",
        water_meter_units:"M³ por pulso",
        name:"Nome",
        latitude:"Latitude",
        longitude:"Longitude",
        create:"Criar",
        update:"Atualizar",
        field:"Plantação",
        cancel:"Cancelar",
        delete:"Deletar",
        ok:"OK",
        confirmation:"Confirmação",
        confirm:"Confirmar",
        yes:"Sim",
        no:"Não",
        home:"Início",
        change:"Modificar",
        forgot:"Esqueceu",
        password:"Senha",
        login:"Entrar",
        prefix:"Prefixo",
        register:"Registrar",
        settings:"Configurações",
        amount:"Quantidade",
        date_and_time:"Data & Hora",
        report:"Inserir",
        hours:"Horas",
        report_irrigation:"Inserir Irrigação",
        report_nitrogen:"Inserir Nitrogênio",
        irrigation_reported_successfully:"Irrigação inserida com sucesso",
        is_manure:"É esterco?",
        irrigation:"Irrigação",
        select_sensors:"Sensores selecionados",
        select_all:"Selecionar tudo",
        manure:"Esterco",
        nitrogen:"Nitrogênio",
        fertilization_history:"Histórico de fertilização",
        water_pressure_history:"Histórico de Potencial Hídrico",
        transmitter_id:"Identificação do transmissor",
        support_ndvi:"Suportar NDVI?",
        i_agree_to:"Eu concordo em",
        terms_and_conditions:"Termos e condições",
        language:"Idioma",
        last_update:"Última atualização: ",
        hours_ago:"há {0} horas",
        hour_ago:"há {0} hora",
        day_ago:"há {0} dia",
        days_ago:"há {0} dias",
        minute_ago:"há {0} minuto",
        minutes_ago:"há {0} minutos",
        login_page:"Página de Entrada",
        Sand:"Arenoso",
        "Loamy Sand":"Areia Franca",
        "Sandy Loam":"Franco arenoso",
        Loam:"Franco",
        Silt:"Siltoso",
        "Silty Loam":"Franco siltoso",
        "Sandy Clay Loam":"Franco argilo arenoso",
        "Clay Loam":"Franco argiloso",
        "Silty Clay Loam":"Franco argilo siltoso",
        "Sandy Clay":"Argilo arenoso",
        "Silty Clay":"Argilo siltoso",
        Clay:"Argiloso",
        "Mix of Peat types":"Turfa",
        "Coconut fiber":"Fibra de coco",
        "Mixed Tuff":"Outros substratos orgânicos",
        Alfafa:"Alfafa",
        last_seen:"Última visualização",
        recent_analog_values:"Valores analógicos recentes",
        recent_rssi_values:"Valores RSSI recentes",
        recent_battery_values:"Valores recentes de bateria",
        recent_c1_values:"Valores C1 recentes",
        modem_version:"Versão do Modem",
        modem_hardware:"Hardware de modem",
        iccid:"ICCID",
        sample_interval: "Intervalo de amostragem",
        micro_version: "Versão micro",
        date:"Data",
        value:"Valor",
        sim_activation:"SIM sendo ativado, aguarde 10 minutos antes de testar novamente",
        enter_transmitter_id:"Insira a identificação do transmissor",
        Cabbage:"Repolho",
        Carrot:"Cenoura",
        Cauliflower:"Couve-flor",
        Celery:"Salsão",
        Lettuce:"Alface",
        Onion:"Cebola",
        Spinach:"Espinafre",
        Radish:"Rabanete",
        Eggplant:"Berinjela",
        "Sweet pepper":"Pimentão",
        Tomato:"Tomate",
        Cantaloupe:"Melão",
        Cucumber:"Pepino",
        "Pumpkin (Winter Squash)":"Abóbora (de inverno)",
        Squash:"Abóbora",
        Zucchini:"Abobrinha",
        "Sweet Melon":"Meão Doce",
        Beet:"Beterraba",
        Cassava:"Mandioca",
        Potato:"Batata",
        "Sweet potato":"Batata-doce",
        "Sugar Beet":"Beterraba sacarina",
        Bean:"Feijão",
        "Faba bean, broad bean":"Feijão fava",
        "Green Gram":"Grão de bico",
        Cowpea:"Feijão fradinho",
        "Peanut (Groundnut)":"Amendoim",
        Lentil:"Lentilha",
        Pea:"Ervilha",
        Soybean:"Soja",
        Artichoke:"Alcachofra",
        Asparagus:"Aspargo",
        Cotton:"Algodão",
        Flax:"Linhaça",
        "Castorbean (Ricinus)":"Castorbean (Ricinus)",
        Safflower:"Cártamo",
        Sesame:"Sésame",
        Sunflower:"Girassol",
        Barley:"Cevada",
        Oat:"Aveia",
        Wheat:"Trigo",
        Maize:"Milho",
        Millet:"Milheto",
        Sorghum:"Sorgo",
        Rice:"Arroz",
        Alfalfa:"Alfafa",
        Bermuda:"Grama bermuda",
        Sudan:"Grama Sudan",
        Sugarcane:"Cana-de-açúcar",
        Banana:"Banana",
        Pineapple:"Abacaxi",
        Grape:"Uva",
        Hop:"Lúpulo",
        Citrus:"Citrus",
        Olive:"Oliveira",
        Pistachio:"Pistache",
        Walnut:"Noz",
        "Cattails, Bulrush":"Taboa",
        Mango:"Manga",
        Grapefruit:"Toranja",
        Garden:"Plantas de Jardim",
        Grass:"Grama",
        "Genus Citrus":"Citrus (Gênero)",
        Avocado:"Abacate",
        Coffee:"Café",
        Corn:"Milho",
        Lychee:"Lichia",
        "Peach, Nectarine":"Pêssego, Nectarina",
        Watermelon:"Melancia",
        "Date Palm":"Tâmara",
        Pomegranate:"Romã",
        Cannabis:"Cânhamo",
        Pepper:"Pimenta",
        Blackberry:"Amora",
        Strawberry:"Morango",
        Pecan:"Noz-pecã",
        installation_date:"data de instalação",
        soil_water_deficit:"Deficiência hídrica no solo",
        "n_in_organic_matter":"N na matéria orgânica",
        "initial_concentrations":"Concentrações iniciais",
        "should_automate_irrigation":"Automatize a irrigação",
        "kc_harvest_date":"Data de colheita",
        "kc_harvest_date_invalid_data_message":"Insira uma data de colheita  válida",
        "graphs":"Gráficos",
        "overview":"Relatório",
        "login_check_your_email":"Verifique seu email",
        "create_new_password":"Criar nova senha",
        "notifications":"Notificações",
        "version":"Versão",
        "account_settings":"Configuração de conta",
        "send_reset_code":"Enviar código de redefinição",
        "forgot_password_subtitle":"Insira seu email e enviaremos o código de redefinição de senha",
        "login_title":"Entrar",
        "back":"Voltar",
        "estimated":"Estimado",
        "farm":"Unidade Agricola",
        "location":"Localização",
        "trasmitter_test":"Teste do transmissor",
        "new":"🎉 Novo!",
        "download_the_app":"Baixe o aplicativo para celular",
        "take_the_app_to_the_field":"Leve N-Drip Connect como você para o campo",
        "continue":"Continuar",
        "resend":"Reenviar",
        "min_temp":"Temp Min",
        "max_temp":"Temp Max",
        "etc":"ETc",
        "distribution":"Distribuição",
        "my fields":"Minhas lavouras",
        "sensor_batch":"Lote de sensores",
        "transmission_interval":"Intervalo de transmissão",
        "meta_data":"Metadados",
        "start":"Início",
        "end":"Fim",
        "discharge":"Vazão",
        "recent wfr samples":"Valores recentes do contador de água ",
        "street":"Rua",
        "satellite":"Satélite",
        "topography":"Topografia",
        "ndvi": "NDVI",
        "choose map": "Escolha no mapa",
        "day_sunday": "Domingo",
        "day_monday": "Segunda-feira",
        "day_tuesday": "Terça-feira",
        "day_wednesday": "Quarta-feira",
        "Tday_thursday": "Quinta-feira",
        "day_friday": "Sexta-feira",
        "day_saturday": "Sábado",
        "planning": "Planejamento",
        "monitoring": "Monitoramento",
        "planning_days": "Dias",
        "planning_weeks": "Semanas",
        "planning_publish": "Publicar",
        "recent_errors_values": "Valores de erros recentes",
        "sim_last_event": "Último evento do cartão SIM ",
        "leaf_length": "Comprimento foliar ",
        "stress_level_no_stress": "Sem estresse",
        next_actions: "Próximas ações",
        tomorrow: "Amanhã",
        later: "Mais tarde",
        coming_soon: "Em breve",
        no_planner_events: "Sem ações futuras neste momento",
        history_graph: "Gráfico histórico",
        planning_planned: "PLANEJADO",
        planning_used: "EXECUTADO",
        event: "evento",
        hcl: "HCl",
        hpo: "HPO",
        note: "Observação",
        planning_note_placeholder: "Escreva aqui sua mensagem",
        sensors: "sensores",
        valves: "válvulas",
        type: "tipo",
        "coming up":"chegando",
        locate_coordinates:"Localizar coordenadas",
        ignore_sensor_during_calculations:"Ignorar sensor nos cálculos",
        ignore_sensor_during_calculations_and_hide:"Ocultar e ignorar sensor nos cálculos",
        consider_as_flow_meter:"Consider como medidor de vazão",
        water_potential_graph:"Gráfico de potencial hídrico",
        edit:"Editar",
        alerts:"Alertas",
        stop_data_collection:"Interromper a coleta de dados",
        accumulated_water_input:"Entrada de água acumulada",
        precipitation:"Chuva",
        water_potential:"Potencial hídrico",
        minimum:"mínimo",
        maximum:"máximo",
        max_bigger_than_min:"Valor máximo deve ser maior que o valor mínimo",
        min_smaller_than_max:"Valor máximo deve ser menor que o valor mínimo",
        last_year: "Último ano",
        moderator:"Moderador",
        designed_pressure:"Pressão estimada",
        field_size:"Tamanho do campo",
        "duplicate_field": "duplicar campo",
        "duplicate": "duplicar",
         "offset": "Offset",
        "days_from_sowing": "Dias após semeadura",
        "show_archived": "Mostrar campos arquivados",
        "events_list": "Lista de eventos",
        "notes": "Comentários",
        "week_total": "Total semanal",
        "season_total": "Total safra",
        "no_reported": "Nenhum evento adicionado",
        "report_event": "Adicionar evento",
        "save": "salvar",
        "rain": "Chuva",
        "starting_time": "hora de início",
        "fertilization": "fertilização",
        "reported": "Adicionados",
        "plant_height": "Altura de planta",
        "fert_tank_height": "Nível do tanque de fertilização",
        "Fert_tank_volume": "Volume do tanque de fertilização",
        "allow_notifications": "Permitir notificações",
        "notifications_channels": "Em quais canais você será notificado",
        "event_type": "Tipo de evento",
        "push": "Ativar",
        "recommendations": "Recomendações",
        "field_notifications": "Quais notificações você receberá para cada campo",
        "none": "Nenhum",
        "notifications_phase": "Controlar os tipos de notificação e canais pelos quais serei notificado, por campo",
        "alerts_tooltip": "Os alertas sinalizam eventos adversos que exigem ação imediata para proteção das lavouras",
        "recent_connectivity_values": "Valores recentes de conectividade",
        "alerts_message_continuous_irrigation_notification_center_title": "Alerta de Irrigação contínua - fieldX.",
        "alerts_message_continuous_irrigation_notification_center_body": "Detectamos irrigação contínua há mais de 2 dias para o campo. Para proteção das lavouras, recomendamos verificar se o campo está sendo overirrigado.",
        "alerts_message_sensor_extreme_dry_conditions_notification_center_title": "Condições extremas de seca detectadas no campo - Dados recebidos do sensorX",
        "alerts_message_long_dry_period_notification_center_title": "Longo período sem irrigação - Recomendamos a irrigação do fieldX",
        "alerts_message_sensor_extreme_dry_conditions_notification_center_body": "Os dados recebidos pelo sensorX indicam condição extremamente seca no campo.Para assegurar dados precisos e proteção das lavouras, recomendamos verificar o plano de irrigação e o sistema de irrigação ao redor do sensor.",
        "alerts_message_long_dry_period_notification_center_body": "O fieldX não recebe água há 10 dias, o que pode resultar em estresse hídrico. Para proteção das lavouras, recomendamos irrigar o campo seguindo seu plano de irrigação N-Drip.",
        "alerts_message_drying_trend_notification_center_title": "Detectamos divergências em relação às recomendações de irrigação planejadas para o campo",
        "alerts_message_wetting_trend_notification_center_title": "Tendência de umedecimento detectada no fieldX",
        "alerts_message_drying_trend_notification_center_body": "Observamos tendência decrescente no potencial hídrico do fieldX, abaixo do nível recomendado. Para otimização das lavouras, revise e ajuste seu plano de irrigação de acordo com as recomendações de N-Drip.",
        "alerts_message_wetting_trend_notification_center_body": "Observamos tendência crescente do potencial hídrico no fieldX, acima do nível recomendado. Para otimização das lavouras, revise e ajuste seu plano de irrigação atual de acordo com as recomendações de N-Drip.",
        "alerts_message_excessive_heat_notification_center_title": "Alerta de Onda de Calor",
        "alerts_message_excessive_heat_notification_center_body": "Uma onda de calor significativa está prevista para ocorrer na área do fieldX nos próximos dias. Para otimização das lavouras e minimizar os danos causados pelo calor extremo, revise e ajuste seu plano de irrigação atual de acordo com nossas recomendações atualizadas.",
        "alerts_message_extreme_cold_notification_center_title": "Alerta de Onda de Frio",
        "alerts_message_extreme_cold_notification_center_body": "Uma onda de frio significativa está prevista para ocorrer na área do fieldX nos próximos dias. Para proteger sua lavoura dos danos causados pela geada, revise e ajuste seu plano de irrigação de acordo com as nossas recomendações atualizadas.",
        "notifications_welcome_message_title": "Bem-vindo ao novo painel de notificações",
        "notifications_welcome_message_body": "Fique por dentro de eventos importantes atuais e esperados em suas lavouras no desktop e dispositivos móveis",
        "alerts_message_dryland_long_dry_period_notification_center_title": "Longo período seco detectado",
        "alerts_message_dryland_long_dry_period_notification_center_body": "O fieldX não recebe água há 10 dias, o que pode resultar em estresse hídrico.",
        "alerts_message_dryland_sensor_exterme_dry_notification_center_title": "Condições extremas de seca detectadas no campo - Dados recebidos do sensorX",
        "alerts_message_dryland_sensor_exterme_dry_notification_center_body": "Os dados recebidos pelo sensorX indicam condição extremamente seca no campo.",
        "alerts_message_dryland_drying_trend_notification_center_title": "Tendência de seca detectada no seu campo",
        "alerts_message_dryland_drying_trend_notification_center_body": "Observamos tendência decrescente no potencial hídrico do fieldX, o que pode resultar em estresse hídrico",
        "alerts_message_dryland_wetting_trend_notification_center_title": "Tendência de umedecimento detectada no seu campo",
        "alerts_message_dryland_wetting_trend_notification_center_body": "Observamos tendência crescente no potencial hídrico do fieldX, o que pode resultar em estresse hídrico",
        "alerts_message_dryland_excessive_heat_notification_center_title": "Alerta de Onda de Calor",
        "alerts_message_dryland_excessive_heat_notification_center_body": "Uma onda de calor significativa está prevista para ocorrer na área do fieldX nos próximos dias.",
        "alerts_message_dryland_extreme_cold_notification_center_title": "Alerta de Onda de Frio",
        "alerts_message_dryland_extreme_cold_notification_center_body": "Uma onda de frio significativa está prevista para ocorrer na área do fieldX nos próximos dias.",
        "alerts_message_dryland_ndvi_notification_center_title": "Redução da atividade vegetativa",
        "alerts_message_dryland_ndvi_notification_center_body": "Análises recentes do histórico de imagens de NDVI revelaram uma redução significativa no crescimento vegetativo no fieldX. Para otimizar a saúde de sua lavoura, revise suas práticas atuais de irrigação.",
        "no_notifications": "No momento não há notificações",
        "all_fields": "Todos os campos",
        "current_field": "Campo atual",
        "planner_days_shorts_sun": "Dom",
        "planner_days_shorts_mon": "Seg",
        "planner_days_shorts_tue": "Ter",
        "planner_days_shorts_wed": "Qua",
        "planner_days_shorts_thu": "Qui",
        "planner_days_shorts_fri": "Sex",
        "planner_days_shorts_sat": "Sab",
        "shorts_months_january": "Jan",
        "shorts_months_february": "Fev",
        "shorts_months_march": "Mar",
        "shorts_months_april": "Abr",
        "shorts_months_may": "Mai",
        "shorts_months_june": "Jun",
        "shorts_months_july": "Jul",
        "shorts_months_august": "Ago",
        "shorts_months_september": "Set",
        "shorts_months_october": "Out",
        "shorts_months_november": "Nov",
        "shorts_months_december": "Dez",
        "dont_show_again": "Não mostrar novamente",
        "notifications_settings": "Configurações de notificação",
        "read_more": "Leia mais",
        "show_less": "Mostrar menos",
        "distribution_menu_select": "Selecionar distribuição",
        "farming_unit_menu_select": "Selecionar Unidade Agrícola",
        "field_menu_select": "Selecionar campo",
        "recent_field": "Campo recente",
        "discharge_test": "Teste de Vazão",
        "planner_season_start": "Início da safra",
        "planner_season_end": "Fim da safra",
        "planner_growth_phase_initial": "Inicial",
        "planner_growth_phase_development": "Desenvolvimento",
        "planner_growth_phase_middle": "Médio",
        "planner_growth_phase_late": "Final",
        "planner_export_button": "Exportar",
        "planner_add_event_button": "Adicionar evento",
        "planner_water_treatment": "Tratamento de água",
        "planner_add_button": "Adicionar",
        "create_planner_item": "Adicionar novo evento ",
        "update_planner_item": "Atualizar evento",
        "planner_start_time": "Hora de início",
        "dashboard_move_to_archive": "Mover para o arquivo",
        "dashboard_unarchived": "Desarquivado",
        "Insights_title_county": "Condado",
        "Insights_group_by": "Agrupado por",
        "edit_field_tank_type": "Tipo do reservatório de água",
        "edit_field_measured_irrigation_rate": "Taxa de irrigação medida",
        "edit_field_custom_irrigation_rate": "Customizar taxa de irrigação",
        "edit_field_representative_input_checkbox": "Utilize os valores medidos de taxa de irrigação quando disponíveis. Isso impactará o planejamento de irrigação",
        "wtr_reported_successfully": "Tratamento de água reportado com sucesso",
        "kc_phases_stress_checkbox": "Aplicar estresse",
        "kc_phases_stress_tooltip": "Iniciar um período planejado de estresse hídrico de acordo com a seguinte redução percentual na ETc",
        "edit_field_irrigation_timeframe_source_soil": "limitação pelo tipo de solo",
        "edit_field_irrigation_timeframe_source_irrigation_rate": "taxa de irrigação",
        "edit_field_irrigation_timeframe_source_user": "definido por usuário",
        "edit_field_irrigation_timeframe_tooltip": "Limitação original:",
        "source": "Origem",
        "discharge_result_exceeding_hint": "O resultado do teste diverge da curva de tendência da vazão",
        "Timeframe_end_of_season": "Fim da safra",
        "Farming_unit_entity_basic_info": "Informações básicas",
        "Farming_unit_entity_add_new_button": "Adicionar novo",
      }
    },
    zh: {
      translations: {
        'login_registration_confirmation_message':'请点击您邮箱中收到的确认链接以确认您在我们系统里的登记',
        'login_registration_after_confirmation_message':'确认之后，去往',
        'login_registration_after_confirmation_message_2':'登录',
        'login_enter_your_email':'输入您的邮箱',
        'login_enter_your_password':'输入您的密码',
        'login_enter_a_valid_email':'输入一个当前使用的邮箱账号',
        'login_enter_a_valid_confirmation_code':'输入一个确认密码',
        'login_forgot_password':'忘记密码？',
        'login_enter_verification_code_message':'输入您在邮箱账号中收到的认证码',
        'login_enter_confirmation_code_message':'输入您在邮箱账号中收到的确认密码',
        'login_enter_confirmation_code':'输入确认密码',
        'confirmation_code':'确认密码',
        'login_new_password':'新密码',
        'login_enter_a_valid_password':'输入一个有效的密码',
        'login_dont_have_account_message':'还未开录账户？',
        'login_sign_up':'登录',
        'login_enter_first_name':'输入名',
        'login_enter_last_name':'输入姓',
        'login_enter_phone_number':'输入电话号码',
        'login_password_limitation_message':'密码最起码8位数字并包括一个大写和一个小写字母',
        'account_first_name_invalid_data_message':'输入有效的名',
        'account_last_name_invalid_data_message':'输入有效的姓',
        'account_phone_number_invalid_data_message':'输入有效的电话号码',
        'account_email_invalid_data_message':'输入有效的电子邮箱',
        'account_notifications':'收到提醒信息？',
        'loading':'正在下载。。。',
        'kc_developments':'',
        'kc_auto_generate':'自动生成',
        'kc_crop_type_invalid_data_message':'输入作物种类',
        'kc_variety':'品种',
        'kc_variety_invalid_data_message':'输入品种',
        'kc_feature':'特征',
        'kc_feature_invalid_data_message':'输入特征',
        'kc_sow_date':'播种日期',
        'kc_sow_date_invalid_data_message':'输入播种日期',
        'kc_kc_table':'KC表格',
        'kc_by_month':'以月计',
        'kc_by_growth_phase':'以生长阶段计',
        'kc_add_development_phase':'增加一个生长阶段',
        'kc_phase_title_placeholder':'插入阶段名称',
        'kc_first_phase_starts':'第一阶段开始日期',
        'edit_field_choose_area_message':'您需要在地块中选择一个区域',
        'edit_field_field_name':'地块名称',
        'edit_field_last_recommendation':'上一次的建议',
        'edit_field_no_fields':'没有区域可显示 😟',
        'edit_field_field_name_invalid_data_message':'输入有效的区域名称',
        'edit_field_area_invalid_data_message':'使用左边下拉菜单标注您的区域范围',
        'edit_field_time_zone_invalid_message':'输入时区',
        'edit_field_water_meter_sensors':'水表探头',
        'edit_field_installation_date':'安装日期',
        'edit_field_installation_date_invalid_message':'输入安装日期',
        'edit_field_contract_duration':'合同周期（年）',
        'edit_field_contract_duration_invalid_message':'合同周期必须大于0',
        'edit_field_delete_field_message':'确认需要删除该地块？',
        'irrigate_command':'请开始灌溉',
        'leaf_length':"叶子长度",
        'get_ready_to_irrigate':'准备开始灌溉',
        'dont_irrigate_command':'不需要灌溉',
        'stress_level_very_high':'缺水状况非常严重',
        'stress_level_high':'缺水状况严重',
        'stress_level_getting_low':'缺水状况降低了',
        'stress_level_optimal':'湿润状态最佳',
        'no_recommendation_available':'没有建议',
        'expiration_date':'服务合同终止日期',
        'soil_type':'土壤类型',
        'crop_type':'作物类型',
        'dashboard_irrigation_amount':'灌溉水量',
        'dashboard_my_fields':'我的地块',
        'dashboard_delete_field':'删除地块',
        'dashboard_copy_field_id':'拷贝ID',
        'dashboard_edit_field':'编辑地块',
        'dashboard_add_field':'增加地块',
        'dashboard_new_field':'新地块',
        'dashboard_loading_data_message':'我们正在收集相关信息，预计需要6-8小时',
        'management_distribution_units_management':'分布单元管理',
        'transmitter_admin_page':'传输器测试',
        'management_admins_only':'本栏仅限管理员',
        'management_farming_units_management':'种植单元管理',
        'management_delete_distribution_message':'确认删除该分销商？',
        'management_manage_distribution_users_title':'管理（分销商）用户',
        'management_enter_a_valid_username':'输入用于名 - ex. user@domain.com',
        'management_enter_a_valid_distribution_name':'输入分布系统名称',
        'management_enter_a_valid_farming_unit_name':'输入种植单元名称',
        'management_enter_a_valid_region':'输入地区',
        'management_farming_unit_name':'种植单元名称',
        'management_farming_unit_salesforce_id':'销售人员ID',
        'management_distribution_name':'分布名称',
        'time_zone':'时区',
        'tensiometers':'张力计',
        'distribution_unit':'分布单元',
        'farming_unit':'种植单元',
        'field_amount':'地块数量',
        'region':'地区',
        'actions':'开始',
        'close':'结束',
        'user':'用户',
        'role':'角色',
        'admin':'管理员',
        'viewer':'普通用户',
        'account':'账户',
        'logout':'退出',
        'first_name':'名',
        'last_name':'姓',
        'phone_number':'电话号码',
        'email':'邮箱',
        'units':'单位',
        'area':'面积',
        'gdd':'GDD',
        'et0':'ET0',
        'leached':'渗滤量',
        'plant':'作物',
        'soil':'土壤',
        'temperature':'温度',
        'weight':'重量',
        'pressure':'压力',
        'length':'长度',
        'volume':'体积',
        'estimated_nitrogen_values':'预估的含氮量',
        'temperature_celsius':'摄氏度',
        'temperature_fahrenheit':'华氏度',
        'weight_kg':'公斤',
        'weight_lb':'磅',
        'weight_tonne':'吨',
        'weight_short_ton':'短吨',
        'pressure_kpa':'kPa',
        'pressure_psi':'PSI',
        'pressure_cbar':'CBar',
        'pressure_mm':'mm',
        'pressure_pa':'Pa',
        'length_mm':'mm',
        'length_inch':'英寸',
        'volume_cubic_metre':'立方米',
        'volume_gallon':'加仑',
        'volume_bushel':'蒲式耳',
        'volume_acre_foot':'英亩英尺',
        'volume_acre_inch':'英亩英寸',
        'volume_megaliter':'百万升',
        'phase':'阶段',
        'kc':'Kc',
        'duration':'时长',
        'days':'天',
        'months_january':'1月',
        'months_february':'2月',
        'months_march':'3月',
        'months_april':'4月',
        'months_may':'5月',
        'months_june':'6月',
        'months_july':'7月',
        'months_august':'8月',
        'months_september':'9月',
        'months_october':'10月',
        'months_november':'11月',
        'months_december':'12月',
        'choose_date':'选择日期',
        'last_day':'最后一日',
        'last_week':'最后一周',
        'last_two_weeks':'最后两周',
        'last_month':'最后一个月',
        'last_six_months':'最后六个月',
        'supporting_data':'支撑数据',
        'water_stress_level':'缺水状况',
        'fertilization_gauge_title':'氮含量水平',
        'map_view':'地图视角',
        'serial_no':'系列号',
        'sensor_serial_no':'探头系列号',
        'sensor_latitude':'探头经度',
        'sensor_longitude':'探头纬度',
        'water_meter_units':'每脉冲立法米数',
        'name':'名称',
        'latitude':'经度',
        'longitude':'纬度',
        'create':'生成',
        'update':'更新',
        'field':'地块',
        'cancel':'取消',
        'delete':'删除',
        'ok':'OK',
        'confirmation':'确认',
        'confirm':'确认',
        'yes':'是',
        'no':'否',
        'home':'主页',
        'change':'更改',
        'forgot':'忘记',
        'password':'密码',
        'login':'登录',
        'prefix':'前缀',
        'register':'注册',
        'settings':'设置',
        'amount':'数量',
        'date_and_time':'日期时间',
        'report':'报告',
        'hours':'小时数',
        'report_irrigation':'报告灌溉量',
        'report_nitrogen':'报告施肥量',
        'irrigation_reported_successfully':'灌溉报告成功发送',
        'is_manure':'使用的是粪肥？',
        'irrigation':'灌溉',
        'select_sensors':'选择探头',
        'manure':'粪肥',
        'nitrogen':'氮',
        'fertilization_history':'施肥历史',
        'water_pressure_history':'作物根系水势历史数据',
        'support_ndvi':'支持NDVI?',
        'i_agree_to':'我同意',
        'terms_and_conditions':'条款和条件',
        'language':'语言',
        'last_update':'最后一次更新：',
        'hours_ago':'{0} 小时前',
        'hour_ago':'{0} 小时前',
        'day_ago':'{0} 天前',
        'days_ago':'{0} 天前',
        'minute_ago':'{0} 分钟前',
        'minutes_ago':'{0} 分钟前',
        'login_page':'登录页',
        'based_on':'基于',
        'test':'测试',
        'Sand':'沙',
        'Loamy Sand':'肥沃沙土',
        'Sandy Loam':'砂壤土',
        'Loam':'壤土',
        'Silt':'粉砂',
        'Silty Loam':'粉砂壤土',
        'Sandy Clay Loam':'砂粘壤土',
        'Clay Loam':'粘壤土',
        'Silty Clay Loam':'粉砂粘壤土',
        'Sandy Clay':'砂粘土',
        'Silty Clay':'粉砂粘土',
        'Clay':'粘土',
        'Mix of Peat types':'混合泥炭种类',
        'Coconut fiber':'椰子纤维',
        'Mixed Tuff':'混合凝灰岩',
        'Alfafa':'苜蓿',
        'last_seen':'最后一次显示：',
        'recent_analog_values':'近期模拟数据',
        'recent_rssi_values':'近期RSSI数据',
        'recent_battery_values':'近期电池数据',
        'recent_c1_values':'近期C1数据',
        'modem_version':'调制解调器版本',
        'modem_hardware':'调制解调器软件',
        'iccid':'ICCID',
        'date':'日期',
        'value':'数据',
        'sim_activation':'芯片正在启动，请稍等10分钟再进行下一次测试',
        'transmitter_id':'传输器ID',
        'enter_transmitter_id':'输入传输器ID',
        'Cabbage':'卷心菜',
        'Carrot':'胡萝卜',
        'Cauliflower':'菜花',
        'Celery':'芹菜',
        'Lettuce':'生菜',
        'Onion':'洋葱',
        'Spinach':'菠菜',
        'Radish':'萝卜',
        'Eggplant':'茄子',
        'Sweet pepper':'甜椒',
        'Tomato':'西红柿',
        'Cantaloupe':'哈密瓜',
        'Cucumber':'黄瓜',
        'Pumpkin (Winter Squash)':'南瓜（冬季南瓜）',
        'Squash':'南瓜',
        'Zucchini':'西葫芦',
        'Sweet Melon':'甜瓜',
        'Beet':'甜菜',
        'Cassava':'木薯',
        'Potato':'土豆',
        'Sweet potato':'红薯',
        'Sugar Beet':'甜菜',
        'Bean':'豆角',
        'Faba bean, broad bean':'芸豆',
        'Green Gram':'绿豆',
        'Cowpea':'豇豆',
        'Peanut (Groundnut)':'花生',
        'Lentil':'小扁豆',
        'Pea':'豌豆',
        'Soybean':'大豆',
        'Artichoke':'朝鲜蓟',
        'Asparagus':'芦笋',
        'Cotton':'棉花',
        'Flax':'亚麻籽',
        'Castorbean (Ricinus)':'蓖麻',
        'Safflower':'红花',
        'Sesame':'芝麻',
        'Sunflower':'向日葵',
        'Barley':'大麦',
        'Oat':'燕麦',
        'Wheat':'小麦',
        'Maize':'玉米',
        'Millet':'小米',
        'Sorghum':'高粱',
        'Rice':'水稻',
        'Alfalfa':'苜蓿',
        'Bermuda':'百慕大',
        'Sudan':'苏丹',
        'Sugarcane':'甘蔗',
        'Banana':'香蕉',
        'Pineapple':'菠萝',
        'Grape':'葡萄',
        'Hop':'Hop',
        'Citrus':'柑橘',
        'Olive':'橄榄',
        'Pistachio':'开心果',
        'Walnut':'核桃',
        'Cattails, Bulrush':'香蒲',
        'Mango':'芒果',
        'Grapefruit':'柚子',
        'Garden':'花园',
        'Grass':'草地',
        'Genus Citrus':'柑橘属类',
        'Avocado':'牛油果',
        'Coffee':'咖啡',
        'Corn':'玉米',
        'Lychee':'荔枝',
        'Peach, Nectarine':'桃子',
        'Watermelon':'西瓜',
        'Date Palm':'蜜枣',
        'Pomegranate':'石榴',
        'Cannabis':'大麻',
        'Pepper':'青椒',
        'Blackberry':'黑莓',
        'Strawberry':'草莓',
        'Pecan':'胡桃',
        'Pear':'梨',
        'soil_water_deficit':'土壤水份缺乏状况',
        'n_in_organic_matter':'有机质中的氮含量',
        'initial_concentrations':'初始含量',
        'should_automate_irrigation':'应当自动灌溉',
        'kc_harvest_date':'收获日期',
        'kc_harvest_date_invalid_data_message':'输入收获日期',
        'graphs':'曲线',
        'overview':'全貌',
        'micro_version':'微观版本',
        'login_check_your_email':'请查收邮件',
        'create_new_password':'生成一个新的密码',
        'notifications':'消息通知',
        'version':'版本',
        'account_settings':'账户设置',
        'send_reset_code':'发送重置口令',
        'forgot_password_subtitle':'输入你的邮箱，我们将发送一个口令重新设置你的密码',
        'login_title':'登录',
        'back':'返回',
        'estimated':'估计值',
        'farm':'农场',
        'location':'地点',
        'trasmitter_test':'传输器测试',
        'installation_date':'安装日期',
        'new':'🎉 上新！',
        'download_the_app':'下载手机应用软件',
        'take_the_app_to_the_field':'带着Ndrip Connect 到田间',
        'continue':'继续',
        'resend':'重新发送',
        'min_temp':'最低温度',
        'max_temp':'最高温度',
        'etc':'等等',
        'distribution':'分布',
        'my fields':'我的地块',
        'sensor_batch':'探头批量号',
        'transmission_interval':'信号传输间隔',
        'meta_data':'其他数据',
        'start':'开始',
        'end':'结束',
        'discharge':'排放水量',
        'recent wfr samples':'最近的流量计读数',
        'street':'街道',
        'satellite':'卫星',
        'topography':'地形图',
        'ndvi':'植被密度指数',
        'choose map':'选择地图',
        'day_sunday': "星期日",
        'day_monday': "星期一",
        'day_tuesday': "星期二",
        'day_wednesday': "星期三",
        'Tday_thursday': "星期四",
        'day_friday': "星期五",
        'day_saturday': "星期六",
        'planning': "计划",
        'monitoring': "监测",
        'planning_days': "天",
        'planning_weeks': "星期",
        'planning_publish': "公布",
        'recent_errors_values': "近期的错误数据",
        'sim_last_event': "最后一次SIM卡活动",
        'stress_level_no_stress': "未发生缺乏状况",
        'next_actions': "下一措施",
        'tomorrow': "明天",
        'later': "稍后",
        'coming_soon': "随后",
        'no_planner_events': "目前为止没有计划实施的措施",
        'history_graph': "历史曲线",
        'planning_planned': "",
        'planning_used': "实际读取数据",
        'event': "发生事件",
        ' hcl': "",
        'hpo': "次氯酸钠",
        'note': "备注",
        'planning_note_placeholder':"请留言",
        'coming up':"建议的工作内容",
        water_potential_graph:"水势曲线",
        accumulated_water_input:"水势曲线",
        precipitation:"沉淀",
        ignore_sensor_during_calculations: "计算过程中忽略探头",
        ignore_sensor_during_calculations_and_hide:"计算过程中忽略探头",
        locate_coordinates: "当地坐标",
        edit: "编辑",
        consider_as_flow_meter:"视为水表",
        alerts:"预警",
        stop_data_collection:"数据采集",
        petiole:"叶柄",
        water_potential:"水势",
        'minimum':"最小",
        'maximum':"最大",
        'max_bigger_than_min':"最大值必须大于最小值",
        last_year: "去年",
      }
    },
    es: {
      translations: {
        edit_field_pre_season_irrigation_start_date:"Fecha de inicio del riego pre-temporada",
        'login_registration_confirmation_message': "Por favor, confirme su registro haciendo clic en el enlace de confirmación recibido por correo eletronico en tu cuenta",
        'login_registration_after_confirmation_message': "Después de la confirmación, vaya a",
        'login_registration_after_confirmation_message_2': "e iniciar sesión",
        'login_enter_your_email': "Ingrese tu correo eletrónico",
        'login_enter_your_password': "Ingrese tu contraseña",
        'login_enter_a_valid_email': "Ingrese una cuenta de correo eletrónico válida",
        'login_enter_a_valid_confirmation_code': "Ingrese un código de confimación válido",
        'login_forgot_password': "¿Se te olvidó tu contraseña?",
        'login_enter_verification_code_message': "Ingrese el código de verificación que recibió en su cuenta de correo eletrónico",
        'login_enter_confirmation_code_message': "Ingrese el código de confirmación que recibió en su cuenta de correo eletrónico",
        'login_enter_confirmation_code': "Ingrese el código de confirmación",
        'confirmation_code': "Código de confirmación",
        'login_new_password': "Nueva contraseña",
        'login_enter_a_valid_password': "Ingrese una contraseña válida",
        'login_dont_have_account_message': "¿No tienes cuenta?",
        'login_sign_up': "Regístrate",
        'login_enter_first_name': "Ingrese tu primer nombre",
        'login_enter_last_name': "Ingrese tu apellido",
        'login_enter_phone_number': "Ingrese tu número telefónico",
        'login_password_limitation_message': "La contraseña debe tener al menos 8 caracteres con al menos una letra mayúscula y minúscula.",
        'account_first_name_invalid_data_message': "Ingrese un primer nombre válido",
        'account_last_name_invalid_data_message': "Ingrese un apellido válido",
        'account_phone_number_invalid_data_message': "Ingrese un número telefónico válido",
        'account_email_invalid_data_message': "Ingrese un correo eletrónico válido",
        'account_notifications': "¿Quieres recibir notificaciones?",
        'loading': "Cargando...",
        'kc_developments': "Desarrollo",
        'kc_auto_generate': "Autogenerar",
        'kc_crop_type_invalid_data_message': "Ingrese un tipo de cultura válido",
        'kc_variety': "Variedad",
        'kc_variety_invalid_data_message': "Ingrese una variedade válida",
        'kc_feature': "Característica",
        'kc_feature_invalid_data_message': "Ingrese una característica válida",
        'kc_sow_date': "Fecha de siembra",
        'kc_sow_date_invalid_data_message': "Ingrese una fecha de siembra válida",
        'kc_kc_table': "Tabela de Kc",
        'kc_by_month': "Por mes",
        'kc_by_growth_phase': "Por fase de crecimiento",
        'kc_add_development_phase': "Agregar una fase de desarrollo",
        'kc_phase_title_placeholder': "Inserte el título de la fase",
        'kc_first_phase_starts': "La primera fase comienza el",
        'edit_field_choose_area_message': "Elige un área para su campo",
        'edit_field_field_name': "Nombre del campo",
        'edit_field_last_recommendation': "Última recomendación",
        'edit_field_no_fields': "No hay campos para mostrar",
        'edit_field_field_name_invalid_data_message': "Ingrese un nombre de campo válido",
        'edit_field_area_invalid_data_message': "Marque su área de campo usando el menú de dibujo a la izquierda",
        'edit_field_time_zone_invalid_message': "Ingrese una zona horaria válida",
        'edit_field_water_meter_sensors': "Sensores de medición de agua",
        'edit_field_installation_date': "Fecha de instalación",
        'edit_field_installation_date_invalid_message': "Ingrese una fecha de instalación válida",
        'edit_field_contract_duration': "Duración del contrato (años)",
        'edit_field_contract_duration_invalid_message': "La duración del contrato debe ser mayor que 0",
        'edit_field_delete_field_message': "Seguro que quieres eliminar el campo?",
        'irrigate_command': "Por favor, irrigue",
        'get_ready_to_irrigate': "Prepárate para irrigar",
        'dont_irrigate_command': "No es necesario irrigar",
        'stress_level_very_high': "El nivel de estrés es muy alto",
        'stress_level_high': "El nivel de estrés es alto",
        'stress_level_getting_low': "El nivel de estrés está bajando",
        'stress_level_optimal': "El nivel de estrés es óptimo",
        'no_recommendation_available': "No hay recomendación disponible",
        'expiration_date': "Fecha de expiración",
        'soil_type': "Tipo de suelo",
        'crop_type': "Tipo de cultivo",
        'dashboard_irrigation_amount': "Cantidad de irrigación",
        'dashboard_my_fields': "Mis campos",
        'dashboard_delete_field': "Eliminar",
        'dashboard_copy_field_id': "Copiar el campo",
        "dashboard_edit_field": "Editar",
        'dashboard_add_field': "Agregar campo",
        'dashboard_new_field': "Nuevo campo",
        'dashboard_loading_data_message': "Estamos recopilando datos, debe tomar entre 6 a 8 horas.",
        'management_distribution_units_management': "Gestión de Unidades de Distribución",
        'transmitter_admin_page': "Evaluación de transmisores",
        'management_admins_only': "Solo administradores",
        'management_farming_units_management': "Manejo de Unidades Agrícolas",
        'management_delete_distribution_message': "¿Estás seguro de que deseas eliminar la distribución?",
        'management_manage_distribution_users_title': "Administrar (distribución) usuarios",
        'management_enter_a_valid_username': "Ingrese un nombre de usuario válido - ej. usuario@dominio.com",
        'management_enter_a_valid_distribution_name': "Ingrese un nombre de distribución válido",
        'management_enter_a_valid_farming_unit_name': "Ingrese un nombre de unidade agrícola válido",
        'management_enter_a_valid_region': "Ingrese una región válida",
        'management_farming_unit_name': "Nombre de la unidade agrícola",
        'management_farming_unit_salesforce_id': "Salesforce ID",
        'management_distribution_name': "Nombre de distribución",
        'time_zone': "Zona horaria",
        'tensiometers': "Tensiómetros",
        'distribution_unit': "Unidad de distribución",
        'farming_unit': "Unidad agrícola",
        'field_amount': "Cantidad de campos",
        'region': "Región",
        'actions': "Acciones",
        'close': "Cerrar",
        'user': "Usuario",
        'role': "Papel",
        'admin': "Administrador",
        'viewer': "Espectador",
        'account': "Cuenta",
        'logout': "Cerrar sesión",
        'first_name': "Primer nombre",
        'last_name': "Apellido",
        'phone_number': "Número de teléfono",
        'email': "Correo eletrónico",
        'units': "Unidades",
        'area': "Zona",
        'gdd': "GDD",
        'et0': "ETo",
        'leached': "Lixiviado",
        'plant': "Planta",
        'soil': "Suelo",
        'temperature': "Temperatura",
        'weight': "Peso",
        'pressure': "Presión",
        'length': "Extensión",
        'volume': "Volumen",
        'estimated_nitrogen_values': "Valores estimados de nitrógeno",
        'temperature_celsius': "Celsius",
        'temperature_fahrenheit': "Fahrenheit",
        'weight_kg': "Kg",
        'weight_lb': "lb",
        'weight_tonne': "Tonelada",
        'weight_short_ton': "Tonelada corta",
        'pressure_kpa': "kPa",
        'pressure_psi': "PSI",
        'pressure_cbar': "CBar",
        'pressure_mm': "mm",
        'pressure_pa': "Pa",
        'length_mm': "mm",
        'length_inch': "Polegada",
        'volume_cubic_metre': "Metro cúbico",
        'volume_gallon': "Galón",
        'volume_bushel': "Bushel",
        'volume_acre_foot': "Pie por acre",
        'volume_acre_inch': "Pie por polegada",
        'volume_megaliter': "megalitro",
        'phase': "Fase",
        'kc': "Kc",
        'duration': "Duración",
        'days': "Días",
        'months_january': "Janero",
        'months_february': "Febrero",
        'months_march': "Marzo",
        'months_april': "Abril",
        'months_may': "Mayo",
        'months_june': "Junio ",
        'months_july': "Julio",
        'months_august': "Agosto",
        'months_september': "Septiembre",
        'months_october': "Octubre",
        'months_november': "Noviembre",
        'months_december': "Diciembre",
        'choose_date': "Elegir fecha",
        'last_day': "Último día",
        'last_week': "Última semana",
        'last_two_weeks': "Últimas 2 semanas",
        'last_month': "Último mes",
        'last_six_months': "Últimos 6 meses",
        'supporting_data': "Datos de soporte",
        'water_stress_level': "Nivel de estrés",
        'fertilization_gauge_title': "Niveles de nitrógeno",
        'map_view': "Vista del mapa",
        'serial_no': "Número de serie",
        'sensor_serial_no': "Número de serie del sensor",
        'sensor_latitude': "Latitud del sensor",
        'sensor_longitude': "Longitud del sensor",
        'water_meter_units': "m3 por pulso",
        'name': "Nombre",
        'leaf_length':"Longitud de las hojas",
        'latitude': "Latitud",
        'longitude': "Longitud",
        'create': "Crear",
        'update': "Actualizar",
        'field': "Campo",
        'cancel': "Cancelar",
        'delete': "Borrar",
        'ok': "OK",
        'confirmation': "Confirmación",
        'confirm': "Confirmar",
        'yes': "Si",
        'no': "No",
        "home": "Inicio",
        'change': "Cambiar",
        'forgot': "Olvidó",
        'password': "Contraseña",
        'login': "Iniciar sésion",
        'prefix': "Prefijo",
        'register': "Registro",
        'settings': "Configuraciones",
        'amount': "Cantidad",
        'date_and_time': "Fecha y hora",
        'report': "Insertar",
        'hours': "Horas",
        'report_irrigation': "Insertar irrigación",
        'report_nitrogen': "Insertar nitrógeno",
        'irrigation_reported_successfully': "Irrigación informada con éxito",
        'is_manure': "¿Es estiercól?",
        'irrigation': "Irrigación",
        'select_sensors': "Seleccionar sensores",
        'manure': "Estiercól",
        'nitrogen': "Nitrógeno",
        'fertilization_history': "Historial de fertilización",
        'water_pressure_history': "Historia del potencial hídrico",
        'transmitter_id': "Identificación del transmisor",
        'support_ndvi': "¿Soporte NDVI?",
        'i_agree_to': "Estoy de acuerdo con",
        'terms_and_conditions': "Términos y condiciones",
        'language': "Idioma ",
        'last_update': "Última actualización",
        'hours_ago': "hace {0} horas",
        'hour_ago': "1 hora antes",
        'day_ago': "Hace 1 día",
        'days_ago': "Hace {0} días",
        'minute_ago': "Hace 1 minuto",
        'minutes_ago': "Hace {0} minutos",
        'login_page': "Página de inicio",
        'based_on': "Basado en",
        'test': "Prueba",
        'Sand': "Arenoso",
        'Loamy Sand': "Arenoso franco",
        'Sandy Loam': "Franco arenoso",
        'Loam': "Franco",
        'Silt': "Limoso",
        'Silty Loam': "Franco limoso",
        'Sandy Clay Loam': "Franco arcillo arenoso",
        'Clay Loam': "Franco arcilloso",
        'Silty Clay Loam': "Franco arcillo limoso",
        'Sandy Clay': "Arcillo arenoso",
        'Silty Clay': "Arcillo limoso",
        'Clay': "Arcilloso",
        'Mix of Peat types': "Turba",
        'Coconut fiber': "Fibra de coco",
        'Mixed Tuff': "Otros sustratos orgánicos",
        'Alfafa': "Alfafa",
        'Artichoke': "Alcachofa",
        'Asparagus': "Espárragos",
        'Avocado': "Palta",
        'last_seen': "Visto por última vez",
        'recent_analog_values': "Valores analógicos recientes",
        'recent_rssi_values': "Valores RSSI recientes",
        'recent_battery_values': "Valores recientes de la bateria",
        'recent_c1_values': "Valores C1 recientes",
        'modem_version': "Versión de lo modém ",
        'modem_hardware': "Hardware de lo modém ",
        'iccid': "ICCID",
        'date': "Fecha",
        'value': "Valor",
        'sim_activation': "La SIM se está activando, espere 10 minutos antes de volver a probar",
        'enter_transmitter_id': "Ingrese da ID del transmisor",
        'Cabbage': "Repollo",
        'Carrot': "Zanahoria",
        'Cauliflower': "Coliflor",
        'Celery': "Apio",
        'Lettuce': "Lechuga",
        'Onion': "Cebolla",
        'Spinach': "Espinaca",
        'Radish': "Rábano",
        'Eggplant': "Berenjena",
        'Sweet pepper': "Pimiento morrón",
        'Tomato': "Tomate",
        'Cantaloupe': "Melón",
        'Cucumber': "Pepino",
        'Pumpkin (Winter Squash)': "Calabaza (Invierno)",
        'Squash': "Calabaza",
        'Zucchini': "Calabacín",
        'Sweet Melon': "Melón dulce",
        'Beet': "Remolacha",
        'Cassava': "Mandioca",
        'Potato': "Papa",
        'Sweet potato': "Papa dulce",
        'Sugar Beet': "Remolacha azucarera",
        'Bean': "Frijol",
        'Faba bean, broad bean': "Haba",
        'Green Gram': "Garbanzo",
        'Cowpea': "Frijoles negros",
        'Peanut (Groundnut)': "Maní",
        'Lentil': "Lenteja",
        'Pea': "Guisante",
        'Soybean': "Soja",
        'Cotton': "Algodón",
        'Flax': "Linaza",
        'Castorbean (Ricinus)': "Castorbean (ricinus)",
        'Safflower': "Cártamo",
        'Sesame': "Sésamo",
        'Sunflower': "Girasol",
        'Barley': "Cebada",
        'Oat': "Avena",
        'Wheat': "Trigo",
        'Maize': "Maíz",
        'Millet': "Mijo",
        'Sorghum': "Sorgo",
        'Rice': "Arroz",
        'Alfalfa': "Alfafa",
        'Bermuda': "Grama Bermuda",
        'Sudan': "Hierba de Sudán",
        'Sugarcane': "Caña de azúcar",
        'Banana': "Banana",
        'Pineapple': "Piña",
        'Grape': "Uva",
        'Hop': "Lúpulo",
        'Citrus': "Citrus",
        'Olive': "Oliveira",
        'Pistachio': "Pistacho",
        'Walnut': "Nuez",
        'Cattails, Bulrush': "Taboa",
        'Mango': "Mango",
        'Grapefruit': "Pomelo",
        'Garden': "Plantas de jardín",
        'Grass': "Gramo",
        'Genus Citrus': "Cítricos (género)",
        'Coffee': "Café",
        'Corn': "Maíz",
        'Lychee': "Lychee",
        'Peach, Nectarine': "Durazno, Nectarina",
        'Watermelon': "Sandía",
        'Date Palm': "Dátil",
        'Pomegranate': "Granada",
        'Cannabis': "Cáñamo",
        'Pepper': "Pimienta",
        'Blackberry': "Mora",
        'Strawberry': "Fresa",
        'Pecan': "Nuez",
        'Pear': "Pera",
        'soil_water_deficit': "Déficit hídrico del suelo",
        'n_in_organic_matter': "N en materia orgánica",
        'initial_concentrations': "Concentraciones iniciales",
        'should_automate_irrigation': "Automatizar el riego",
        'kc_harvest_date': "Fecha de cosecha",
        'kc_harvest_date_invalid_data_message': "Ingrese una fecha de cosecha válida",
        'graphs': "Gráficos",
        'overview': "Reporte",
        'micro_version': "Versión micro",
        'login_check_your_email': "Consulte su correo electrónico",
        'create_new_password': "Crear nueva contraseña",
        'notifications': "Notificaciones",
        'version': "Versión",
        'account_settings': "Configuracion de cuenta",
        'send_reset_code': "Enviar código de reinicio",
        'forgot_password_subtitle': "Ingrese su correo electrónico y le enviaremos el código de restablecimiento de contraseña",
        'login_title': "Entrar",
        'back': "Volver",
        'estimated': "Estimado",
        'farm': "Unidad Agrícola",
        'location': "Localización",
        'trasmitter_test': "Prueba de transmisor",
        'installation_date': "Fecha de instalación",
        'new': "¡Nuevo!",
        'download_the_app': "Descarga la aplicación móvil",
        'take_the_app_to_the_field': "Tome N-Drip Connect mientras ingresa al campo",
        'continue': "Seguir",
        'resend': "Reenviar",
        'min_temp': "Temp Min",
        'max_temp': "Temp Max",
        'etc': "ETc",
        'distribution': "Distribución",
        'my fields': "Mis campos",
        'sensor_batch': "Lote de sensor",
        'transmission_interval': "Intervalo de transmisión",
        'meta_data': "Metadatos",
        'start': "Comienzo",
        'end': "Fin",
        'discharge': "Descarga",
        'recent wfr samples': "Valores recientes del medidor de agua",
        'street': "Calle",
        'satellite': "Satélite",
        'topography': "Topografía",
        'ndvi': "NDVI",
        'choose layer': "Elegir capa",
        'coordinates': "",
        'planning_days':"Días",
        'planning_weeks':"Semanas",
        'planning_publish':"Publicar",
        'recent_errors_values':"Valores de error recientes",
        'sim_last_event':"Último evento del SIM",
        'stress_level_no_stress':"Sin estrés",
        'next_actions':"Próximas acciones",
        'tomorrow':"Mañana",
        'later':"Más tarde",
        'coming_soon':"En breve",
        'no_planner_events':"No hay acciones futuras en este momento",
        'history_graph':"Gráfico histórico",
        'planning_planned':"PLANEADO",
        'planning_used':"EJECUTADO",
        'event':"Evento",
        ' hcl':"HCl",
        'hpo':"HPO",
        'note':"Observación",
        'planning_note_placeholder':"Escribe aquí tu mensaje",
        sensors:"sensores",
        valves:"válvulas",
        type:"tipo",
        'coming up':"Subiendo",
        water_potential_graph:"Grafico de potencial de agua",
        locate_coordinates:"Encontrar coordenadas",
        ignore_sensor_during_calculations:"Ignorar el sensor en los cálculos",
        ignore_sensor_during_calculations_and_hide:"Ocultar e ignorar el sensor en los cálculos",
        consider_as_flow_meter:"Considerar como medidor de flujo",
        edit:"Editar",
        alerts:"Alertas",
        stop_data_collection:"Detener la recogida de datos",
        accumulated_water_input:"Entrada de agua acumulada",
        precipitation:"lluvia",
        petiole:"Pecíolo",
        water_potential:"Potencial de agua",
        'minimum':"mínimo",
        'maximum':"máximo",
        'max_bigger_than_min':"El valor máximo debe ser mayor que el valor mínimo",
        'min_smaller_than_max':"El valor mínimo debe ser menor que el valor máximo",
        last_year: "Último ano",
        moderator:"Moderador",
        designed_pressure:"Presión estimada",
        'field_size':"Tamaño del campo",
        "duplicate_field": "duplicar el campo",
        "duplicate": "duplicar",
         "offset": "Offset",
        "Nitrogen graph": "Gráfico de Nitrógeno",
        "select_all": "Seleccionar todo",
        "select_water_input": "seleccionar entrada de agua",
        "Planned irrigation": "Riego planificado",
        "days_from_sowing": "Días después de la siembra",
        "day_sunday": "Domingo",
        "day_monday": "Lunes",
        "day_tuesday": "Martes",
        "day_wednesday": "Miércoles",
        "day_thursday": "Jueves",
        "day_friday": "Viernes",
        "day_saturday": "Sábado",
        "week": "semana",
        "season": "estación",
        "today": "hoy",
        "show_archived": "Mostrar campos archivados",
        "events_list": "Lista de eventos",
        "notes": "Notas",
        "week_total": "Total semanal",
        "season_total": "Total temporada",
        "no_reported": "Ningún evento agregado",
        "report_event": "Reportar evento",
        "save": "ahorrar",
        "rain": "lluvia",
        "starting_time": "hora de inicio",
        "fertilization": "fertilización",
        "reported": "Agregados",
        "plant_height": "Altura de planta",
        "fert_tank_height": "Nivel del tanque de fertilización",
        "Fert_tank_volume": "Volumen del tanque de fertilización",
        "allow_notifications": "Permitir notificaciones",
        "notifications_channels": "En qué canales serás notificado",
        "event_type": "Tipo de evento",
        "push": "Activar",
        "recommendations": "Recomendaciones",
        "field_notifications": "Quais notificações você receberá para cada campo",
        "none": "Ninguno",
        "notifications_phase": "Controlar los tipos de notificación y canales a través de los cuales seré notificado",
        "alerts_tooltip": "Las alertas indican eventos adversos que requieren acción inmediata para proteger los cultivos",
        "recent_connectivity_values": "Valores de conectividad recientes",
        "alerts_message_continuous_irrigation_notification_center_title": "Alerta de riego continuo - fieldX",
        "alerts_message_continuous_irrigation_notification_center_body": "Hemos detectado riego continuo durante más de 2 días para el campo. Para proteger los cultivos, le recomendamos comprobar si el campo está siendo regado en exceso.",
        "alerts_message_sensor_extreme_dry_conditions_notification_center_title": "Condiciones de sequía extrema detectadas en el campo - Datos recibidos del sensorX",
        "alerts_message_long_dry_period_notification_center_title": "Largo período sin riego -  Recomendados el riego del fieldX",
        "alerts_message_sensor_extreme_dry_conditions_notification_center_body": "Los datos recibidos por el sensorX indican una condición extremadamente seca en el campo. Para garantizar datos precisos y protección de cultivos, recomendamos inspeccionar el plan de riego, y el sistema de riego alrededor del sensor.",
        "alerts_message_long_dry_period_notification_center_body": "El fieldX no ha recibido agua durante 10 días, lo que puede provocar estrés hídrico. Para proteger los cultivos, recomendamos regar el campo siguiendo tu plan de riego N-Drip.",
        "alerts_message_drying_trend_notification_center_title": "Tendencia de secado detectada en su campo",
        "alerts_message_drying_trend_notification_center_body": "Observamos una tendencia decreciente del potencial hídrico en el fieldX, por debajo del nivel recomendado. Para optimizar los cultivos, revisa y ajusta tu plan de riego actual según las recomendaciones de N-Drip.",
        "alerts_message_wetting_trend_notification_center_title": "Tendencia de humedecimiento detectada en su campo",
        "alerts_message_wetting_trend_notification_center_body": "Observamos una tendencia creciente del potencial hídrico en el fieldX, por encima del nivel recomendado. Para optimizar los cultivos, revisa y ajusta tu plan de riego actual según las recomendaciones de N-Drip.",
        "alerts_message_excessive_heat_notification_center_title": "Alerta de ola de calor",
        "alerts_message_excessive_heat_notification_center_body": "Se pronostica una importante ola de calor para la zona del fieldX durante los próximos días. Para proteger sus cultivos de los daños causados por el calor extremo y asegurar riego adequado, revisa y ajusta tu plan de riego actual según nuestras recomendaciones actualizadas.",
        "alerts_message_extreme_cold_notification_center_title": "Alerta de ola de frio",
        "alerts_message_extreme_cold_notification_center_body": "Se pronostica una importante ola de frio para la zona del fieldX durante los próximos días. Para proteger sus cultivos de los daños causados por las heladas, revisa y ajusta tu plan de riego actual según nuestras recomendaciones actualizadas.",
        "notifications_welcome_message_title": "Bem-vindo ao novo painel de notificações",
        "notifications_welcome_message_body": "Fique por dentro de eventos importantes atuais e esperados em suas lavouras no desktop e dispositivos móveis",
        "alerts_message_dryland_long_dry_period_notification_center_title": "Largo período seco detectado",
        "alerts_message_dryland_long_dry_period_notification_center_body": "El fieldX no ha recibido agua durante 10 días, lo que puede provocar estrés hídrico.",
        "alerts_message_dryland_sensor_exterme_dry_notification_center_title": "Condiciones de sequía extrema detectadas en el campo - Datos recibidos del sensorX",
        "alerts_message_dryland_sensor_exterme_dry_notification_center_body": "Los datos recibidos por el sensorX indican una condición extremadamente seca en el campo.",
        "alerts_message_dryland_drying_trend_notification_center_title": "Tendencia de secado detectada en su campo",
        "alerts_message_dryland_drying_trend_notification_center_body": "Observamos una tendencia decreciente del potencial hídrico en el fieldX, lo que puede provocar estrés hídrico.",
        "alerts_message_dryland_wetting_trend_notification_center_title": "Tendencia de humedecimiento detectada en su campo",
        "alerts_message_dryland_wetting_trend_notification_center_body": "Observamos una tendencia creciente del potencial hídrico en el fieldX, lo que puede provocar estrés hídrico.",
        "alerts_message_dryland_excessive_heat_notification_center_title": "Alerta de ola de calor",
        "alerts_message_dryland_excessive_heat_notification_center_body": "Se pronostica una importante ola de calor para la zona del fieldX durante los próximos días.",
        "alerts_message_dryland_extreme_cold_notification_center_title": "Alerta de ola de frio",
        "alerts_message_dryland_extreme_cold_notification_center_body": "Se pronostica una importante ola de frio para la zona del fieldX durante los próximos días.",
        "alerts_message_dryland_ndvi_notification_center_title": "Reducción de la actividad vegetativa",
        "alerts_message_dryland_ndvi_notification_center_body": "Análisis recientes de imágenes históricas del NDVI reveló una reducción significativa en el crecimiento vegetativo en el fieldX. Para optimizar la salud de su cultivo, revise sus prácticas de riego actuales.",
        "no_notifications": "Actualmente no hay notificaciones",
        "all_fields": "Todos los campos",
        "current_field": "Campo actual",
        "planner_days_shorts_sun": "Dom",
        "planner_days_shorts_mon": "Lun",
        "planner_days_shorts_tue": "Mar",
        "planner_days_shorts_wed": "Mie",
        "planner_days_shorts_thu": "Jue",
        "planner_days_shorts_fri": "Vie",
        "planner_days_shorts_sat": "Sab",
        "shorts_months_january": "Ene",
        "shorts_months_february": "Feb",
        "shorts_months_march": "Mar",
        "shorts_months_april": "Abr",
        "shorts_months_may": "Mayo",
        "shorts_months_june": "Jun",
        "shorts_months_july": "Jul",
        "shorts_months_august": "Ago",
        "shorts_months_september": "Sept",
        "shorts_months_october": "Oct",
        "shorts_months_november": "Nov",
        "shorts_months_december": "Dic",
        "dont_show_again": "No mostrar de nuevo",
        "notifications_settings": "Configuración de notificaciones",
        "read_more": "Leer más",
        "show_less": "Mostrar menos",
        "distribution_menu_select": "Seleccionar distribución",
        "farming_unit_menu_select": "Seleccionar Unidade Agrícola",
        "field_menu_select": "Seleccionar campo",
        "recent_field": "Campo reciente",
        "discharge_test": "Prueba de descarga",
        "create_planner_item": "Agregar nuevo evento",
        "update_planner_item": "Actualizar evento",
        "planner_season_start": "Inicio de temporada",
        "planner_season_end": "Fin de temporada",
        "planner_growth_phase_initial": "Inicial",
        "planner_growth_phase_development": "Desarrollo",
        "planner_growth_phase_middle": "Medio",
        "planner_growth_phase_late": "Final",
        "planner_export_button": "Exportar",
        "planner_add_event_button": "Agregar evento",
        "planner_water_treatment": "Tratamiento de agua",
        "planner_add_button": "Agregar",
        "planner_start_time": "Hora de inicio",
        "dashboard_move_to_archive": "Mover al archivo",
        "dashboard_unarchived": "Desarchivado",
        "Insights_title_county": "Condado",
        "Insights_group_by": "Agrupado por",
        "edit_field_measured_irrigation_rate": "Tasa de riego medida",
        "edit_field_tank_type": "Tipo del tanque de agua",
        "edit_field_representative_input_checkbox": "Utilice los valores de tasa de riego medidos quando estén disponibles. Esto afectará la planificación del riego.",
        "edit_field_custom_irrigation_rate": "Personalizar tasa de riego",
        "wtr_reported_successfully": "Tratamiento de agua reportado con éxito",
        "kc_phases_stress_checkbox": "Aplicar estrés",
        "kc_phases_stress_tooltip": "Iniciar un periodo planificado de estrés hídrico según el siguiente porcentaje de reducción de ETc",
        "edit_field_irrigation_timeframe_source_soil": "limitación por tipo de suelo",
        "edit_field_irrigation_timeframe_source_irrigation_rate": "Tasa de riego",
        "edit_field_irrigation_timeframe_source_user": "definido por el usuario",
        "edit_field_irrigation_timeframe_tooltip": "Limitación original:",
        "source": "Origen",
        "discharge_result_exceeding_hint": "El resultado de la prueba se desvia de la curva de tendencia de descarga",
        "Timeframe_end_of_season": "Fin de temporada",
        "Farming_unit_entity_basic_info": "Información básica",
        "Farming_unit_entity_add_new ": "Agregar nuevo",
        "Farming_unit_entity_add_new_button": "Agregar nuevo",
      }
    },
    hin: {
      translations: {

      }
    },

    ger: {
      translations: {

      }
    },
    fre: {
      translations: {

      }
    }
  },
  fallbackLng: "en",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});



export default i18n;
