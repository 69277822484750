import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import React, {useState} from "react";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import {PlanningPageContainer} from "./PlanningPageContainer";
import {getContent, getFirstDateInWeek, getSeasonStartDay} from "./PlannerUtils";
var {PrintAsync} = require("PrintAsync");

var {NDCheckBox} = require("@stories/NDCheckBox");
var moment = require('moment-timezone');
var {upper} = require('@managers/helpers');
var {compare} = require('Utils');
require('./planner.css');

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const StyledFooter = styled('div')`
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
`;

const StyledList = styled('div')`
  overflow-y: auto;
`;

const MenuProps = {
    sx: {
        "&& .css-6hp17o-MuiList-root-MuiMenu-list": {
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export function AddEventButton({fieldId, propsForModal={}, style={}}) {
    const {t} = useTranslation();
    const today = moment();

    let defaultProps = {
        'data-toggle': "modal", 'data-target': "#eventModalId", 'data-start': today.unix(),
        'data-id': fieldId, 'data-action': 'create', 'data-type': 'irrigation'
    };
    propsForModal = Object.assign(defaultProps, propsForModal);

    return(
        <div className='secondary-button'
             style={{height:24, borderRadius:3, paddingLeft:8, paddingRight:12, gap:2, ...style}}
             type="button" {...propsForModal}>
            <img src={require('images/icons/planner/plus.svg')} style={{width: 16}}/>

            <span className='text-style-titles-14-semi-bold text-grey45'>{upper(t('planner_add_event_button'))}</span>
        </div>
    )
}

export function Shadow({direction, style, shouldHide, rowHeight}) {
    let degrees = '';
    let dimension = '100%';

    if (direction == 'left') {
        degrees = '90deg';
        style.width = 8;
        style.height = rowHeight;
    } else if (direction == 'right') {
        degrees = '-90deg';
        style.width = 8;
        style.height = rowHeight + 1;
    } else if (direction == 'top') {
        degrees = '180deg';
        style.height = 8;
    }
    if (shouldHide) {
        dimension = '0%'
    }

    return <div style={{...style, background: `linear-gradient(${degrees}, rgba(145, 157, 180, 0.19) 0%, rgba(145, 157, 180, 0.00) ${dimension})`}}/>
}

function FieldMultiSelection({selected, setSelected, allFields}) {
    const {t} = useTranslation();
    const [showSelect, setShowSelect] = React.useState(false);

    const fieldIDs = Object.keys(allFields).map(index => allFields[index].id);
    const getSelectAllMode = () => {
        if (selected.length == 0) {
            return 0;
        }
        if (selected.length == allFields.length) {
            return 1;
        }
        return -1;
    }

    const handleChange = (id) => {
        if (selected.indexOf(id) > -1) {
            setSelected(selected.filter(x => x !== id));
        } else {
            setSelected([...selected, id]);
        }
    }

    function getPlaceHolder(selected) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        let placeHolder = selected.map(id => Object.values(allFields).find(x=> x.id == id)?.name).join(', ');
        const textWidth = context.measureText(placeHolder).width;
        const maxWidth = 130;
        let overflowText = undefined;
        if (textWidth > maxWidth) {
            let currentWidth = 0;
            let overflowStartIndex = 0;
            for (let i = 0; i < placeHolder.length; i++) {
                currentWidth += context.measureText(placeHolder[i]).width;
                if (currentWidth > maxWidth) {
                    overflowStartIndex = i;
                    break;
                }
            }
            overflowText = placeHolder.slice(overflowStartIndex);

        }

        const commaCount = overflowText ? (overflowText.match(/,/g) || []).length : 0;
        return (
            <div className='row' style={{maxWidth: 240}}>
                <div className='overflow-hidden text-truncate' style={{flex:1, paddingLeft:16}}>{placeHolder}</div>
                {commaCount > 0 &&
                    <div style={{width: 20}}>+{commaCount}</div>}
            </div>
        );
    }

    function handleCancel() {
        setSelected([]);
        setShowSelect(false);
    }

    function onSelectAllClick() {
        setSelected(selected.length === fieldIDs.length ? [] : fieldIDs);
    }

    return (
        <div className="dropdown text-size-14 text-grey71 mr-2 ml-lg-2 mr-lg-3 align-self-center">

            <FormControl sx={{width: 272, height:40, padding:0}}>

                <Select
                    sx={{width: 272, height: 40, left:-10, border: "0.5px solid #DCE0E8"}}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selected}
                    open={showSelect}
                    onOpen={() => setShowSelect(true)}
                    onClose={() => setShowSelect(false)}
                    renderValue={(selected) => getPlaceHolder(selected)}
                    MenuProps={MenuProps}>

                    <MenuItem key='select all' value='select all' onClick={onSelectAllClick} style={{alignItems: 'center', display: 'flex', height: 49,
                        position:'sticky', top: 0, zIndex: 1, background: 'white', borderBottom: '1px solid #ddd'}}>
                        <NDCheckBox mode={getSelectAllMode()} size={'small'}/>
                        <div className='text-style-titles-16-regular' style={{marginLeft: 8}}>{t('select_all')}</div>
                    </MenuItem>
                    <StyledList>
                        {Object(allFields).map(({id, name}) => (
                            <MenuItem key={id} value={id} style={{alignItems:'center', display:'flex', height:32}}
                                        onClick={()=>handleChange(id)}>
                                <NDCheckBox mode={selected.indexOf(id) > -1 ? 1 : 0} size={'small'}/>
                                <div className='text-style-titles-16-regular' style={{marginLeft: 8}}>{name}</div>
                            </MenuItem>
                        ))}
                    </StyledList>

                    <StyledFooter>
                        <button className='ghost-button'
                            style={{width: 88, height: 32, borderRadius: 3, gap: 2, marginRight: 4}}
                            onClick={handleCancel}>
                            <span className='text-style-titles-14-semi-bold text-grey45'>{upper(t('cancel'))}</span>
                        </button>
                        <button className='justify-content-center primary-button' disabled={!selected.length}
                            style={{width: 88, height: 32, borderRadius: 3, gap: 2}}
                            onClick={() => setShowSelect(false)}>
                            <span className='text-style-titles-14-semi-bold text-white'>{upper(t('select'))}</span>
                        </button>
                    </StyledFooter>

                </Select>
            </FormControl>

        </div>

    );
}

export const ExportPopup = ({startWithMonday, allFields, closeExportPopup}) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState([]);

    let downloadButtonStyle = selected.length ? 'primary-button' : 'disabled-button';

    return (
        <div className="export-popup">
            <div style={{paddingBottom: 24}}>
                <div className='text-style-titles-14-semi-bold'>{upper(t('fields'))}</div>
                <FieldMultiSelection selected={selected} setSelected={setSelected} allFields={allFields}/>
            </div>

            <div className='row justify-content-between' style={{width: 272, marginLeft: -2}}>
                <button className='ghost-button' onClick={closeExportPopup}
                     style={{width: 132, height: 40, borderRadius: 3, gap: 2}}>
                    <span className='text-style-titles-14-semi-bold text-grey45'>{upper(t('cancel'))}</span>
                </button>
                <PrintAsync
                    printButton={<div
                        className={['justify-content-center', downloadButtonStyle].join(' ')}
                        style={{width: 132, height: 40, borderRadius: 3, gap: 2}}>
                        <span className='text-style-titles-14-semi-bold text-white'>{upper(t('download'))}</span>
                    </div>}
                    content={(ref) =>

                        <div ref={ref}>
                            {
                                selected.map((fieldID) => {
                                    let currentField = allFields.find(field => field.id == fieldID);
                                    let startDate = getFirstDateInWeek(new Date(getSeasonStartDay(currentField)));
                                    let isWeekViewMode = false;
                                    let dates = getContent(startDate, isWeekViewMode, startWithMonday, 'dates');

                                    return (
                                        <div style={{
                                            pageBreakAfter: 'always',
                                            transform: 'rotate(90deg)',
                                            position: 'relative',
                                            top: 400
                                        }}>
                                            <PlanningPageContainer displaySettings={{startDate, isWeekViewMode, startWithMonday}} printMode={true}
                                                                   isResponsive={!isWeekViewMode} dates={dates} allFields={[currentField]}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }/>

            </div>
        </div>
    );
};