var React = require('react');
const {connect} = require("react-redux");
const {withTranslation, useTranslation} = require("react-i18next");
const {useState, useEffect, useCallback, useMemo} = require("react");
const $ = require("jquery");
const distributionActions = require('distributionActions');
const BasicInfoComponent = require('BasicInfoComponent');

const FarmingUnitModal = React.memo((props) => {

    const {t} = useTranslation();
    const [currentFarmingUnit, setCurrentFarmingUnit] = useState({});
    const [selectedFarmingUnit, setSelectedFarmingUnit] = useState({});
    const [modalTitle, setModalTitle] = useState("Create");
    const [modalAction, setModalAction] = useState("Create");

    let countryRegionDictionary = useMemo(()=>({
        "Please select a country": ["please select a region"],
        "USA- Arizona": ["Yuma", "Parker", "Wellton Mohawk", "Safford"],
        "USA- Nebraska": ["Dawson"],
        "USA- Florida": ["Hastings"],
        "USA- California": ["Blythe"],
        "Italy": ["South", "North", "Center"],
        "India": ["Uttar Pradesh", "Punjab", "Haryana", "Maharashtra"],
        "Israel": ["South", "North", "Center"],
        "Australia": ["Queensland", "New South Wales"],
        "Mexico": ["Guanajuato"],
        "Spain": ["Murcia"]
    }),[]);

    $("#" + props.modalId).on('show.bs.modal', function (event) {

        // Clear all previous state for famring units
        let form = document.getElementById("farming_unit_form");
        form.classList.remove('was-validated');

        let farmingUnitId = event?.target?.dataset?.id;
        if (props.selected_distribution && Object.keys(props.selected_distribution?.farming_units).includes(farmingUnitId) && farmingUnitId !== ""){
        let selectedFarmingUnit = props.selected_distribution.farming_units[farmingUnitId];
        selectedFarmingUnit.mondayID = selectedFarmingUnit.salesforceID;
        delete selectedFarmingUnit.salesforceID;
        setCurrentFarmingUnit(selectedFarmingUnit);
        setSelectedFarmingUnit(selectedFarmingUnit);
        }

          if (farmingUnitId == "") {
            setModalTitle("Create");
            setModalAction("Create");
            setCurrentFarmingUnit({
                name: "",
                mondayID: "",
                country: undefined,
                region: undefined
            })
          } else {
            setModalTitle("Update");
            setModalAction("Update");
          }
    });

    const onSubmitClick = (e) => {

        e.preventDefault();
        e.stopPropagation();
        var form = document.getElementById("farming_unit_form");

        // Local check (given by bootstrap)
        if (form.checkValidity() == true) {

            if (modalAction === "Create") {
                props.createFarmingUnit(props.selected_distribution.id, currentFarmingUnit.name, currentFarmingUnit.mondayID, currentFarmingUnit.country, currentFarmingUnit.region);
                 mixpanel.track("Farming Unit Created", {'Distribution': props.selected_distribution.name, 'Farming Unit':currentFarmingUnit.name});

            } else if (modalAction === "Update") {
                props.updateFarmingUnit(props.selected_distribution.id, currentFarmingUnit.id, currentFarmingUnit.name, currentFarmingUnit.mondayID, currentFarmingUnit.country, currentFarmingUnit.region);
                // check for changes when update farming unit to send the mixpanel
                let changes = checkValuesChangesInFarmingUnit();
                if (Object.keys(changes).length > 0) {
                    mixpanel.track("Farming Unit Edited", {'Changes': changes});
                }
            }
            form.classList.add('was-validated');
            props.clearFarmingUnitActions();
            $('#' + props.modalId).modal('hide');
        }
        form.classList.add('was-validated');
    }

    const checkValuesChangesInFarmingUnit = useCallback(() => {
        const current_farming_unit = currentFarmingUnit;
        const selected_farming_unit = selectedFarmingUnit;
        let changes = {};
        let keysToCheck = ['name', 'mondayID', 'country', 'region'];
        for (let key of keysToCheck) {

            if (current_farming_unit[key] !== selected_farming_unit[key]) {
                changes[key] = current_farming_unit[key];
            }
        }
        return changes;
    }, [currentFarmingUnit, selectedFarmingUnit]);

    let isSaveOptionDisabled = (currentFarmingUnit.name === "" || currentFarmingUnit.mondayID === "" || currentFarmingUnit.country === undefined || currentFarmingUnit.region === undefined) ? true : false;

    const onChangeName = useCallback((newName) => {
        setCurrentFarmingUnit((prev) => ({...prev, name: newName}));
    }, []);

    const onChangeMondayID = useCallback((newID) => {
        setCurrentFarmingUnit((prev) => ({...prev, mondayID: newID}));
    }, []);

    const onChangeCountry = useCallback((newCountry) => {
        setCurrentFarmingUnit((prev) => ({...prev, country: newCountry}));
    }, []);

    const onChangeRegion = useCallback((newRegion) => {
        setCurrentFarmingUnit((prev) => ({...prev, region: newRegion}));
    }, []);

    return (
        <div className="modal fade" id={props.modalId} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <form className="needs-validation" noValidate onSubmit={(e) => {
                onSubmitClick(e)
            }} id="farming_unit_form">

                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{borderRadius: "10px"}}>
                        <div className="modal-header bg-white"
                             style={{borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}}>
                            <div className="modal-title font-weight-bold text-size-32 text-grey45"
                                 id="exampleModalLabel">{modalTitle} {t('farming_unit').toLowerCase()}</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    style={{color: "#4D5674", opacity: 1, outline: "none", padding: "24px"}}>
                                <img src={require('images/icons/edit_field/close_icon.svg')}/>
                            </button>
                        </div>
                        <div className="modal-body bg-grey98">
                            <BasicInfoComponent countryRegionDictionary={countryRegionDictionary}
                                                currentFarmingUnit={currentFarmingUnit} onChangeName={onChangeName}
                                                onChangeMondayID={onChangeMondayID} onChangeCountry={onChangeCountry}
                                                onChangeRegion={onChangeRegion}/>
                        </div>
                        <div className="modal-footer bg-white"
                             style={{borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px"}}>
                            <div className="container text-center">
                                <button type="button"
                                        className="btn btn-outline-secondary m-1 p-0 btn-lg footer-button-cancel"
                                        data-dismiss="modal" aria-label="Close">{t('cancel')}</button>
                                <button type="submit" disabled={isSaveOptionDisabled}
                                        className="btn m-0 p-0 btn-lg footer-button-save bg-blue-primary text-white">{t("save")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
});

const mapStateToProps = function (state) {
    return {
        userData: state.authentication_data.user_data,
        selected_distribution: state.distribution_data.selected_entities.distribution,
        user_details: state.authentication_data.user_details,
        allDistributions: state.distribution_data.distributions,
    }
}
const mapDispatchToProps = {
    createFarmingUnit: distributionActions.create_farming_unit,
    updateFarmingUnit: distributionActions.update_farming_unit,
    clearFarmingUnitActions: distributionActions.clear_farming_unit_action
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FarmingUnitModal));