var React = require('react');
var { Link } = require ('react-router-dom');
var {connect} = require('react-redux');
var distributionActions = require('distributionActions');
const FarmingUnitModal = require("../farming units/FarmingUnitModal");
var rolesManager = require('@managers/rolesManager');
var {get_configuration_config} = require('remoteConfigurationsManager');
const {withTranslation} = require("react-i18next");
const $ = require("jquery");


class FarmingUnitsDropdown extends React.Component {

  constructor(props) {
      super(props);
       this.state = {'hoveredFarmingUnit':undefined};
  }

  onDropdownSelection(key) {
    if (key) {
        this.props.dispatch(distributionActions.selected_entities(this.props.active_distribution,
                            this.props.active_distribution.farming_units[key], null, null));
    }
  }

  handelMouseEnter(fieldId) {
    this.setState({'hoveredFarmingUnit': fieldId});
  }

  handelMouseLeave(fieldId) {

    if (this.state && this.state.hoveredFarmingUnit === fieldId) {
      this.setState({'hoveredFarmingUnit': ""});
    }
  }

  componentDidMount() {
    this.setState({'hoveredFarmingUnit': undefined});
  }

  render() {

    const { t } = this.props;
    let sortedFramingUnits = undefined;
    if (this.props.active_distribution) {

      sortedFramingUnits = Object.values(this.props.active_distribution.farming_units);
      sortedFramingUnits.sort((a, b) => a.name.localeCompare(b.name));
    }
    let farmingUnitModalId = "farming_unit_edit_modal";
    let isAdmin = rolesManager.getIsAdmin(this.props.allDistributions, this.props?.user_details?.is_root);
    let featureFlag = get_configuration_config(this.props.configuration_data,"edit_farming_unit", this.props.active_farming_unit, this.props.active_distribution);
    let farmingUnitPlaceHolder = this.props.active_farming_unit ?  <Link to="" className={"h6 font-weight-normal text-grey45" }>{this.props.active_farming_unit.name}</Link> : <Link to="" className={"h6 font-weight-normal text-grey71" }>{"Add farming unit"}</Link>


    return (
      <div>
        <FarmingUnitModal modalId={farmingUnitModalId}/>
        <div className={"dropdown text-grey71"}>
          <div className="dropdown-toggle d-flex align-items-center" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div  className="overflow-hidden text-truncate">
                {farmingUnitPlaceHolder}
            </div>

          </div>
          <div className="dropdown-menu scrollable-menu" style={{paddingBottom:"4px", paddingTop:"4px"}} aria-labelledby="dropdownMenuButton">
            <div style={{paddingRight:"4px", paddingLeft:"4px"}}>
            {sortedFramingUnits && sortedFramingUnits.map((current_farming_unit) => {

              return (
                  <a className="d-flex dropdown-item text-grey71"
                     style={{borderRadius: "3px", padding: "8px 30px 8px 8px", marginBottom: "2px"}} href="#"
                     key={current_farming_unit.id} onMouseLeave={() => this.handelMouseLeave(current_farming_unit.id)}
                     onMouseEnter={() => this.handelMouseEnter(current_farming_unit.id)} onClick={() => {
                    this.onDropdownSelection(current_farming_unit.id)
                  }}>

                    {current_farming_unit.id === this.props.active_farming_unit.id ?
                        <img style={{marginRight: "8px"}} src={require('images/icons/v_icon.svg')}/>
                        : <div style={{width: "10px", marginRight: "8px"}}></div>}
                    {current_farming_unit.name}
                    {(current_farming_unit.id === this.state.hoveredFarmingUnit && isAdmin && featureFlag) ?
                        <div className={"position-absolute"} style={{right: 12}} data-toggle="modal"
                             data-target={"#farming_unit_edit_modal"} data-id={current_farming_unit.id}
                             onClick={(e) => {
                               e.stopPropagation();
                               const modalElement = document.getElementById('farming_unit_edit_modal');
                               modalElement.setAttribute('data-id', current_farming_unit.id); // Set the data-id attribute
                               $(`#farming_unit_edit_modal`).modal('show');
                               $(".dropdown-toggle").dropdown("hide");
                             }}><img src={require('images/icons/edit_pen_icon.svg')} className="img-fluid"/>
                        </div> : null}
                  </a>
              )
            })}
            </div>
            {isAdmin && featureFlag &&
                <div style={{
                  borderTop: sortedFramingUnits?.length > 0 ? "1px solid #DCE0E8" : "0",
                  paddingTop: "2px",
                  paddingRight: "4px",
                  paddingLeft: "4px"
                }}>
                  <a className="d-flex dropdown-item text-grey45 font-weight-semi-bold text-size-14"
                     style={{borderRadius: "3px", padding: "8px 4px 8px 4px", gap: "4px", justifyContent: "center"}}
                     href="#" data-toggle="modal" data-target={"#farming_unit_edit_modal"} data-id={""}>
                    <img style={{paddingBottom: "2px"}} src={require('images/icons/plus_icon.svg')}
                         className="img-fluid" alt="irrigation ok"/>
                    {t("Farming_unit_entity_add_new_button")}
                  </a>
                </div>}
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = function(state){

  return {
    active_distribution: state.distribution_data.selected_entities.distribution,
    active_farming_unit: state.distribution_data.selected_entities.farming_unit,
    user_details: state.authentication_data.user_details,
    allDistributions: state.distribution_data.distributions,
    configuration_data: state.configuration_data,
  }
}

module.exports = connect(mapStateToProps)(withTranslation()(FarmingUnitsDropdown));
